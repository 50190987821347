// @ts-nocheck
import * as React from "react";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import { FaAngleRight, FaTimes } from "react-icons/fa";
import { Logo } from "../../components/Logo";
import { getUserData, loginUser } from "../../utils/api";
import { ProtectedRoute } from "../../components/ProtectedRoute";
import { LoadingContext, UserContext } from "../../utils/context";

export const Login = () => {
    const navigate = useNavigate();

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loggingIn, setLoggingIn] = React.useState(false);
    const [error, toggleError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const [user, setUser] = React.useContext(UserContext);
    const [loading, setLoading] = React.useContext(LoadingContext);

    React.useEffect(() => {
        if (user) {
            navigate("/dashboard");
        } else {
            console.log("Not logged in");
        }
    }, [user]);

    let submitObject = {
        username: "",
        password: "",
    };

    React.useCallback(
        (e) => {
            console.log(username, password);
        },
        [username, password]
    );

    function Input(props: { placeholder: string; password?: boolean }) {
        return (
            <div className="w-full">
                <h1 className="mt-1 text-sm font-semibold text-gray-600">
                    {props.placeholder}
                </h1>
                <input
                    type={props.password ? "password" : "text"}
                    name="user"
                    className="my-0.5 border-gray-800 hover:border-primary transform focus:border-primary bg-grue-10 shadow-inner border-2 rounded-md h-12 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 p-2 duration-200 caret-primary rounded-r-md"
                    onChange={(e) => {
                        props.password
                            ? setPassword(e.target.value)
                            : setUsername(e.target.value);
                    }}
                />
            </div>
        );
    }

    async function submitLogin(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) {
        const data = {
            id: 1,
            username: username,
            password: password,
            parentAccount: false,
            email: "johndoe@mail.com",
            firstName: "John",
            lastName: "Doe",
            ledger: [
                {
                    description: "Account transfer",
                    total: 3271.34,
                    account: "Personal",
                    date: 1619823600000,
                    id: 123,
                },
                {
                    total: 1000.25,
                    account: "Savings",
                    date: 1623668400000,
                    description: "Monthly Salary",
                    id: 124,
                },
                {
                    total: -50.75,
                    account: "Personal",
                    date: 1624033200000,
                    description: "Dinner with friends",
                    id: 125,
                },
                {
                    total: -200,
                    account: "Personal",
                    date: 1624561200000,
                    description: "New shoes",
                    id: 126,
                },
                {
                    total: 500.5,
                    account: "Savings",
                    date: 1624816800000,
                    description: "Bonus from work",
                    id: 127,
                },
                {
                    total: -35.9,
                    account: "Personal",
                    date: 1625226000000,
                    description: "Groceries",
                    id: 128,
                },
                {
                    total: -100,
                    account: "Personal",
                    date: 1625810400000,
                    description: "Concert tickets",
                    id: 129,
                },
                {
                    total: 350.75,
                    account: "Savings",
                    date: 1626385200000,
                    description: "Extra income",
                    id: 130,
                },
                {
                    total: -70.25,
                    account: "Personal",
                    date: 1626927600000,
                    description: "Movie night",
                    id: 131,
                },
                {
                    total: -500,
                    account: "Personal",
                    date: 1627292400000,
                    description: "Vacation booking",
                    id: 132,
                },
                {
                    total: 500.25,
                    account: "Savings",
                    date: 1627900800000,
                    description: "Investment returns",
                    id: 133,
                },
                {
                    total: -25.5,
                    account: "Personal",
                    date: 1628353200000,
                    description: "Coffee with colleagues",
                    id: 134,
                },
                {
                    total: -75.8,
                    account: "Personal",
                    date: 1628924400000,
                    description: "Dinner date",
                    id: 135,
                },
                {
                    total: 1000.25,
                    account: "Savings",
                    date: 1629375600000,
                    description: "Monthly Salary",
                    id: 136,
                },
                {
                    total: -150,
                    account: "Personal",
                    date: 1629807600000,
                    description: "Gym membership",
                    id: 137,
                },
                {
                    total: -250,
                    account: "Personal",
                    date: 1630326000000,
                    description: "Electronics purchase",
                    id: 138,
                },
                {
                    total: -100.5,
                    account: "Donation",
                    date: 1630987200000,
                    description: "Charitable contribution",
                    id: 139,
                },
                {
                    total: 1000.25,
                    account: "Savings",
                    date: 1631569200000,
                    description: "Monthly Salary",
                    id: 140,
                },
                {
                    total: -50,
                    account: "Personal",
                    date: 1631965200000,
                    description: "Dinner out",
                    id: 141,
                },
                {
                    total: -150.75,
                    account: "Personal",
                    date: 1632638400000,
                    description: "Shopping spree",
                    id: 142,
                },
                {
                    total: -200.25,
                    account: "Donation",
                    date: 1633369200000,
                    description: "Supporting a local charity",
                    id: 143,
                },
            ],
        };
        setUser(data);
        console.log(user);
        setLoggingIn(false);
        navigate("/dashboard");
    }

    if (!loading) {
        return (
            <div className="bg-dark flex flex-col h-screen">
                <div className="grow">
                    <div className="flex items-center h-full">
                        <div className="p-10 mx-auto my-3 bg-white border border-gray-200 rounded-lg">
                            <div className="flex flex-col items-center w-full mb-5">
                                <div>
                                    <div className="bg-primary w-14 h-14 mx-auto rounded-full"></div>
                                    <h1 className="mt-3 text-lg text-gray-300">
                                        Money Manager
                                    </h1>
                                </div>

                                <h1 className="mt-3 text-xl text-gray-800">
                                    Log In to Money Manager
                                </h1>
                                <p className="text-lightgray my-4 text-sm font-normal">
                                    Enter your username and password below
                                </p>
                            </div>
                            <form className="w-72 flex flex-col gap-5">
                                <div className="w-full">
                                    <h1 className="text-lightgray text-xs">
                                        USERNAME
                                    </h1>
                                    <input
                                        type="text"
                                        name="user"
                                        className="text-sm font-normal my-0.5 border-gray-200 hover:border-primary transform focus:border-primary bg-offwhite border rounded-lg h-10 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 px-4 py-2 duration-200 caret-primary"
                                        onChange={(e) => {
                                            setUsername(e.target.value);
                                        }}
                                        placeholder="Username"
                                        maxLength={30}
                                    />
                                </div>
                                <div className="w-full">
                                    <h1 className="text-lightgray text-xs">
                                        PASSWORD
                                    </h1>
                                    <input
                                        type="password"
                                        name="user"
                                        className="text-sm font-normal my-0.5 border-gray-200 hover:border-primary transform focus:border-primary bg-offwhite border rounded-lg h-10 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 px-4 py-2 duration-200 caret-primary"
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                        }}
                                        placeholder="Password"
                                    />
                                </div>
                            </form>
                            <div
                                className={`mt-5 w-72 rounded-lg bg-offwhite flex ${
                                    !error ? `hidden` : ``
                                }`}
                            >
                                <p className="shrink-0 text-ellipsis grow flex items-center h-8 px-2 text-sm font-normal text-red-500 border border-red-500 rounded-l-lg">
                                    {errorMessage}
                                </p>
                                <button
                                    className="focus:border-gray-400 hover:bg-gray-200 shrink-0 group rounded-tr-md rounded-br-md flex items-center justify-around w-8 h-8 transform bg-gray-100 border-t border-b border-r border-gray-200"
                                    onClick={() => toggleError(false)}
                                >
                                    <FaTimes className="text-lightgray group-focus:text-gray-500 group-hover:text-gray-500" />
                                </button>
                            </div>
                            <button
                                onClick={(event) => {
                                    submitLogin(event);
                                }}
                                className={`my-5 h-12 w-full bg-primary rounded-lg group focus:ring-2 ring-primary ring-opacity-50 transition-all flex items-center justify-around text-white hover:bg-transparent hover:text-gray-800 border border-primary shadow-lg duration-100 disabled:bg-primary disabled:ring-0 disabled:brightness-75 disabled:transform disabled:text-white`} /* disabled={!(username.length && password.length) || loggingIn */
                            >
                                <div className="flex items-center font-normal">
                                    <h1>
                                        {loggingIn ? "Logging in..." : "Log In"}
                                    </h1>
                                </div>
                            </button>
                            <div className="flex justify-center gap-1 mx-auto mt-10">
                                <p className="text-lightgray text-sm font-normal">
                                    Don't have an account?
                                </p>
                                <button
                                    onClick={() => {
                                        navigate("/register");
                                    }}
                                    className="text-primary hover:underline focus:underline text-sm font-medium duration-100"
                                >
                                    Sign Up
                                </button>
                            </div>
                        </div>
                        <div className="absolute bottom-0 flex justify-center w-screen h-auto my-2">
                            <p className="w-72 text-xs text-center text-gray-400 whitespace-pre-wrap">
                                By using this service, you are agreeing to the{" "}
                                <a
                                    href="/legal/terms"
                                    className="hover:text-gray-200 focus:text-primary text-xs font-medium text-gray-400 underline duration-100"
                                >
                                    Terms of Service
                                </a>{" "}
                                and{" "}
                                <a
                                    href="/legal/privacy"
                                    className="hover:text-gray-200 focus:text-gray-200 text-xs font-medium text-gray-400 underline duration-100"
                                >
                                    Privacy Policy
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <ProtectedRoute />
        </div>
    );
};
