import * as React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export const ButtonPrimary = (props: {text: string, redirect: string, small?: boolean, arrow?: boolean}) => {
    const navigate = useNavigate();

    return (
        <button onClick={() => { navigate(props.redirect); }} className={`justify-center w-full xs:w-auto rounded-xl ${!props.small ? `h-12 px-7` : `h-8 px-4 rounded-lg`} bg-primary hover:bg-top group focus:ring-2 ring-primary ring-opacity-50 flex items-center text-white duration-300`}>
            <h1 className="font-semibold">{props.text}</h1>
            {
                props.arrow ?
                    <FaAngleRight className="ml-2 text-xl group-hover:translate-x-1 group-focus:translate-x-1 duration-300 ease-out"></FaAngleRight>
                    :
                    null
            }
        </button>
    );
}

export const ButtonSecondary = (props: { text: string, redirect: string, small?: boolean, arrow?: boolean, borderless?: boolean }) => {
    const navigate = useNavigate();

    return (
        <button onClick={() => { navigate(props.redirect); }} className={`justify-center w-full rounded-xl ${!props.small ? `h-12 px-6` : `h-8 px-4 font-lg rounded-lg`} xs:w-auto  group text-gray-400 ring-opacity-50 transition-all flex items-center  hover:text-gray-600 focus:text-gray-600 ${props.borderless ? `focus:text-gray-600` : `focus:ring-2 ring-gray-400 border-gray-400 hover:border-gray-600 border-2`} duration-300`}>
            <h1 className="font-semibold">{props.text}</h1>
            {
                props.arrow ?
                    <FaAngleRight className="ml-2 text-xl group-hover:translate-x-1 group-focus:translate-x-1 duration-300 ease-out"></FaAngleRight>
                    :
                    null
            }
        </button>
    );
}