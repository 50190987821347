import React from "react";
import "./index.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import * as Pg from "./pages";
import { getUserData } from "./utils/api";
import { UserContext, LoadingContext } from "./utils/context";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { LoaderLg } from "./components/Loader";

type user = {
    id: number;
    username: string;
    parentAccount: boolean;
    email: string;
    firstName: string;
    lastName: string;
    ledger: Array<any>;
};

function App() {
    const navigate = useNavigate();

    const [user, setUser] = React.useState(null as user | null);
    const [loading, setLoading] = React.useState(true);
    const [apiOutage, setApiOutage] = React.useState(false);

    // console.log(window.location.protocol + '//' + window.location.host);

    React.useEffect(() => {
        const data = null;
        setUser(data);
        console.log(data);
        setLoading(false);
        if (window.location.pathname === "/dashboard" && !user) {
            navigate("/");
        }
    }, []);

    if (!loading) {
        return (
            <UserContext.Provider value={[user, setUser]}>
                <LoadingContext.Provider value={[loading, setLoading]}>
                    <div className="bg-primary absolute flex items-center justify-center w-full h-10 text-white">
                        Showcase only.{" "}
                        {window.location.pathname == "/" ||
                        window.location.pathname == "/login"
                            ? " Enter any username and password."
                            : ""}
                    </div>
                    <Routes>
                        make a path that immediately redirects to the login page
                        <Route path="/" element={<Pg.Login />} />
                        <Route path="/dashboard" element={<Pg.Dashboard />} />
                        <Route
                            path="/dashboard/parent"
                            element={<Pg.DashboardParent />}
                        />
                        <Route path="/login" element={<Pg.Login />} />
                        <Route path="/register" element={<Pg.Register />} />
                        <Route path="/logout" element={<Pg.Logout />} />
                        <Route
                            path="/legal/privacy"
                            element={<Pg.PrivacyPolicy />}
                        />
                        <Route
                            path="/legal/terms"
                            element={<Pg.TermsPolicy />}
                        />
                        <Route path="*" element={<Pg.NotFound />} />
                    </Routes>
                </LoadingContext.Provider>
            </UserContext.Provider>
        );
    }

    if (!loading && apiOutage) {
        return (
            <div className="flex items-center justify-around h-screen">
                <div>
                    <h1 className="text-primary mx-auto my-3 text-lg font-semibold">
                        API Outage
                    </h1>
                    <div className="shrink mx-auto">
                        <LoaderLg color="text-primary" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <ProtectedRoute />
        </div>
    );
}

// < Route path="test" element={<Pg.Loading />} / >

export default App;
