import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/Pdx_Logo.svg';

export const Logo = (props: { className: string, redirect?: string, color?: string}) => {
    const navigate = useNavigate();

    return (
        <button className="flex select-none items-center hover:text-gray-400 focus:text-gray-400 group outline-none hover:cursor-pointer h-8 w-8 m-2 duration-300 scale-100 transform group" onClick={() => props.redirect ? navigate(props.redirect) : navigate('/')}>
            <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                className="w-full h-full"
                // // {...props}
            >
                <path
                    d="M416.93 63.91 312.05 213.7l-49.14-34.44 86.41-123.42 18.48-26.39A248.92 248.92 0 0 0 250 0C111.93 0 0 111.93 0 250a249.36 249.36 0 0 0 83.07 186.09l125.17-178.76-74.49-52.16L119 121.71v-.06l172.67 121-140.99 201.51-18.48 26.39A248.92 248.92 0 0 0 250 500c138.07 0 250-111.93 250-250a249.36 249.36 0 0 0-83.07-186.09Zm-36 314.44-94.59-66.29L320.72 263l45.53 31.88L381 378.29Z"
                    style={{
                        fill: props.color ?? '#ce3b59',
                    }}
                />
            </svg>
        </button>
    );
}