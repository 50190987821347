// @ts-nocheck
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedRoute } from "../../components/ProtectedRoute";
import { Navbar } from "../../components/Navbar";
import "../../index.css";
import { LoadingContext, UserContext } from "../../utils/context";
import { addLedgerItem, removeLedgerItem } from "../../utils/api";
import { Menu, Transition, Listbox } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import {
    FaMinus,
    FaPlus,
    FaCheck,
    FaPlug,
    FaCaretLeft,
    FaCaretRight,
} from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";

export const Dashboard = () => {
    const navigate = useNavigate();

    const [user, setUser] = React.useContext(UserContext);
    const [loading, setLoading] = React.useContext(LoadingContext);
    const [isOpen, setOpen] = React.useState(false);
    const [isListboxOpen, setListboxOpen] = React.useState(false);

    const [newTransactionDescription, setNewTransactionDescription] =
        React.useState("");
    const [newTransactionTotal, setNewTransactionTotal] = React.useState(0.0);
    const [newTransactionAccount, setNewTransactionAccount] =
        React.useState("");
    const [validTransaction, setValidTransaction] = React.useState(false);

    if (user.parentAccount) {
        navigate("/dashboard/parent");
    }

    React.useEffect(() => {
        if (!user) {
            console.log("Not logged in");
            navigate("/login");
        }
    }, []);

    const accountTypes = ["Personal", "Savings", "Donation"];

    function unixMsStringToDate(str: string) {
        let d = new Date(0);
        d.setUTCSeconds(Math.floor(parseInt(str) / 1000));
        return formatDate(d);
    }

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thurday",
        "Friday",
        "Saturday",
    ];
    function formatDate(d: Date) {
        return [
            `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`,
            `${d.toLocaleTimeString().split(":")[0]}:${
                d.toLocaleTimeString().split(":")[1]
            } ${d.toLocaleTimeString().slice(-2)}`,
        ];
    }

    // create a function with a callback function as an argument

    const DropdownItem = (props: {
        text: string;
        disabled?: boolean | false;
        type?: "danger";
        redirect?: string;
        clickEvent?: Function;
    }) => {
        return (
            <Menu.Item disabled={props.disabled}>
                {({ active, disabled }) => (
                    <button
                        onClick={() =>
                            props.clickEvent ? props.clickEvent() : null
                        }
                        className={`w-full py-1.5 rounded text-left ${
                            active && props.type === undefined
                                ? "bg-gray-200"
                                : active
                                ? "bg-red-200"
                                : null
                        } ${disabled ? "text-gray-600" : null} ${
                            props.type === "danger"
                                ? "text-red-600"
                                : "text-gray-700"
                        } outline-none text-sm flex items-center px-2`}
                    >
                        {props.text}
                    </button>
                )}
            </Menu.Item>
            // <button className={`w-full py-1.5 rounded text-left hover:bg-gray-800 ${props.type === 'danger' ? 'text-red-500' : 'text-gray-400'} text-sm flex items-center px-2`}>
            //     {props.text}
            // </button>
        );
    };

    const SelectorItem = (props: {
        currentData?: any;
        text: string;
        disabled?: boolean | false;
        type?: "danger";
        data?: any;
    }) => {
        return (
            <Listbox.Option
                disabled={props.disabled}
                value={props.data || props.text}
            >
                {({ active, disabled }) => (
                    <button
                        className={`w-full py-1.5 rounded text-left ${
                            props.currentData === props.data
                                ? "text-primary"
                                : "text-gray-700"
                        } ${
                            active && props.type === undefined
                                ? "bg-gray-200"
                                : active
                                ? "bg-red-200"
                                : null
                        } ${disabled ? "text-gray-600" : null} ${
                            props.type === "danger" ? "text-red-600" : null
                        } outline-none text-sm flex items-center px-2`}
                    >
                        {props.text}
                    </button>
                )}
            </Listbox.Option>
            // <button className={`w-full py-1.5 rounded text-left hover:bg-gray-800 ${props.type === 'danger' ? 'text-red-500' : 'text-gray-400'} text-sm flex items-center px-2`}>
            //     {props.text}
            // </button>
        );
    };

    // make a function that will convert any number like 1000 to 1.0K or 1000000 to 1.0M and so on
    function formatMoney(amount: number) {
        if (amount >= 1000000000000) {
            return `$$$`;
        } else if (amount >= 1000000000) {
            return `$${(amount / 1000000000).toFixed(3)}B`;
        } else if (amount >= 100000) {
            return `$${(amount / 1000000).toFixed(3)}M`;
        } else {
            // convert any number as a human readable string with commas and decimal places and if the number is negative, have the negative sign in front
            if (amount < 0) {
                return `-$${Math.abs(amount)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            } else {
                return `$${amount
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
        }
    }

    // make a function that gets the unix timestamp for the start of the current month
    function getStartOfMonth() {
        let d = new Date();
        d.setDate(1);
        d.setUTCMilliseconds(0);
        return d.getTime();
    }

    function handleMoneyInput(e) {
        e.target.value = e.target.value.replace(/[^0-9.-]/g, "");
        if (e.target.value.includes("-")) {
            e.target.value = e.target.value.replace(/[-]/g, "");
            e.target.value = `-${e.target.value}`;
        }
        if (e.target.value.includes(".")) {
            e.target.value =
                e.target.value.split(".")[0] +
                "." +
                e.target.value.split(".")[1].slice(0, 2);
        }
        if (e.target.value.replace(/[- ]/g, "").length === 0) {
            setNewTransactionTotal(0);
        } else {
            setNewTransactionTotal(parseFloat(e.target.value));
        }
    }

    // console.log(getStartOfMonth());

    // console.log(formatMoney(182361283.00));

    const [ledger, setLedgerHere] = React.useState(user.ledger);

    // create a function that filters the ledger by account type and then adds up the totals for each account type
    function filterLedger(type: string, _ledger: Array) {
        let filteredLedger = _ledger.filter((item) => item.account === type);
        let total = 0;
        filteredLedger.forEach((item) => {
            total += item.total;
        });
        return total;
    }

    const [personalBalance, setPersonalBalance] = React.useState(
        filterLedger("Personal", ledger)
    );
    const [savingsBalance, setSavingsBalance] = React.useState(
        filterLedger("Savings", ledger)
    );
    const [donationBalance, setDonationBalance] = React.useState(
        filterLedger("Donation", ledger)
    );
    const [visibleAddBar, setVisibleAddBar] = React.useState(false);

    // React.useEffect(() => {
    //     setPersonalBalance(filterLedger('Personal'));
    //     setSavingsBalance(filterLedger('Savings'));
    //     setDonationBalance(filterLedger('Donation'));
    // }, [ledger]);

    function setLedger(newLedger: any) {
        try {
            newLedger = newLedger.sort((a, b) => {
                return b.date - a.date;
            });
        } catch (e) {
            console.log(e);
        }
        setLedgerHere(newLedger);
        setPersonalBalance(filterLedger("Personal", newLedger));
        setSavingsBalance(filterLedger("Savings", newLedger));
        setDonationBalance(filterLedger("Donation", newLedger));
    }

    React.useEffect(() => {
        // if the transaction total is not 0 and newTransactionAccount is equal to 'Personal' or 'Savings' or 'Donation' and the newTransactionDescription is not empty, then set validTransaction to true
        if (
            newTransactionTotal !== 0 &&
            (newTransactionAccount === "Personal" ||
                newTransactionAccount === "Savings" ||
                newTransactionAccount === "Donation") &&
            newTransactionDescription !== ""
        ) {
            setValidTransaction(true);
        } else {
            setValidTransaction(false);
        }
    }, [newTransactionAccount, newTransactionTotal, newTransactionDescription]);

    const sidebar = [
        { icon: HiDotsVertical, text: "Overview", redirect: "/dashboard" },
        {
            icon: HiDotsVertical,
            text: "Accounts",
            redirect: "/dashboard/accounts",
        },
        {
            icon: HiDotsVertical,
            text: "Transactions",
            redirect: "/dashboard/transactions",
        },
        { icon: HiDotsVertical, text: "Budget", redirect: "/dashboard/budget" },
        {
            icon: HiDotsVertical,
            text: "Reports",
            redirect: "/dashboard/reports",
        },
        {
            icon: HiDotsVertical,
            text: "Settings",
            redirect: "/dashboard/settings",
        },
    ];

    const [currency, setCurrency] = React.useState(0.0);

    // create a function to calculate how many sundays between two dates
    function calculateSundaysBetweenDates(startDate: number, endDate: number) {
        let sundays = 0;
        let currentDate = startDate;
        while (currentDate <= endDate) {
            if (new Date(currentDate).getDay() === 0) {
                sundays++;
            }
            currentDate += 86400000;
        }
        return sundays;
    }
    // create a function to determine what day of the week a month starts on
    function getMonthStartDay(date: number) {
        let d = new Date(date);
        d.setDate(1);
        return d.getDay();
    }

    // create a function to determine how many days are in a month
    function getDaysInMonth(date: number) {
        let d = new Date(date);
        d.setDate(1);
        d.setMonth(d.getMonth() + 1);
        d.setDate(0);
        return d.getDate();
    }

    function getDayOfMonth(date: number) {
        let d = new Date(date);
        return d.getDate();
    }

    function getMsStartOfMonth(date: number) {
        let d = new Date(date);
        d.setDate(1);
        d.setMilliseconds(0);
        date = d.getTime();
        return date;
    }

    function getCalendarDate(date: number, calendarPosition: number) {
        // get the millisecond beginning of the month
        date = getMsStartOfMonth(date);
        const dayMs = 86400000;
        // get the start day of the month (monday - friday)
        const startDay = getMonthStartDay(date);
        const msDay = (calendarPosition - startDay) * dayMs;
        // get the day of the month of msDay
        const dayOfMonth = getDayOfMonth(date + msDay);
        const month = new Date(date + msDay).getMonth();
        const year = new Date(date + msDay).getFullYear();

        return {
            insideMonth:
                new Date(date + msDay).getMonth() === new Date(date).getMonth(),
            date: dayOfMonth,
            month: month,
            year: year,
        };
    }

    let d = new Date(Date.now());
    const [msMonth, setMsMonth] = React.useState(
        `${d.getMonth()}.${d.getFullYear()}`
    );
    let setThisMonth = parseInt(msMonth.split(".")[0]);
    let setThisYear = parseInt(msMonth.split(".")[1]);
    d.setFullYear(setThisYear);
    d.setMonth(setThisMonth);
    d.getDate(0);
    d.setMilliseconds(0);
    const [msMonthStart, setMsMonthStart] = React.useState(d.getTime());

    const [workDaySelected, setWorkDaySelected] = React.useState(Date.now());

    const [workCreditDropdown, setWorkCreditDropdown] = React.useState(null);
    const [workCreditDollars, setWorkCreditDollars] = React.useState(0);

    const [workCredit, setWorkCredit] = React.useState(user.work || []);

    function msTimestampWithinCurrentWeek(date: number) {
        // get the millisecond start of the current week (sunday)
        let d = new Date(Date.now());
        d.setDate(d.getDate() - d.getDay() - 1);
        const msWeekStart = d.getTime();
        const msWeekEnd = msWeekStart + 604800000; // millisecond length of a week
        if (date >= msWeekStart && date < msWeekEnd) {
            return true;
        }
        return false;
    }

    React.useEffect(() => {
        let selfDate = new Date(Date.now());
        let selfDateInMS = selfDate.getTime();
        setWorkDaySelected(selfDateInMS);
        const selfId = `${selfDate.getDate()}.${selfDate.getMonth()}.${selfDate.getFullYear()}`;
        const selfData = workCredit.find(
            (workI: any) => workI.id === selfId
        ) || {
            id: selfId,
            earnable:
                workCredit.sort((a, b) => {
                    return (
                        parseInt(
                            `${b.id.split(".")[2]}${b.id.split(".")[2]}${
                                b.id.split(".")[0]
                            }`
                        ) -
                        parseInt(
                            `${a.id.split(".")[2]}${a.id.split(".")[2]}${
                                a.id.split(".")[0]
                            }`
                        )
                    );
                })[0]?.earnable || 5.0,
            note: "",
        };
        console.log(selfData, selfId);
        setWorkCreditDollars(selfData ? selfData.earnable : 0);
        const work = new Map();
        work.set(0, { message: "No credit (0%)", percent: 0 });
        work.set(50, { message: "Half day (50%)", percent: 50 });
        work.set(100, { message: "Full day (100%)", percent: 100 });
        work.set(120, { message: "Overtime (120%)", percent: 120 });
        setWorkCreditDropdown(
            selfData.percent !== undefined
                ? work.get(selfData.percent)
                : msTimestampWithinCurrentWeek(selfDateInMS)
                ? ""
                : { message: "Not applicable", percent: null }
        );
    }, []);

    if (user && !loading) {
        return (
            <div className=" flex w-full min-h-screen">
                <div className="grow bg-dark place-self-stretch"></div>
                <div className="shrink-0 flex w-3/4 h-auto mt-10">
                    {/* Sidebar */}
                    <div className="bg-dark shrink-0 w-64 h-auto border-r border-gray-200">
                        <div className="mt-7 flex items-center justify-center w-full h-10 mb-10">
                            <p className="text-darkaccent text-lg">
                                Money Manager
                            </p>
                        </div>
                        <div>
                            {sidebar.map((item, index) => {
                                // if the current url path is the same as the redirect path, then make an active variable true
                                let active = window.location.pathname.endsWith(
                                    item.redirect
                                )
                                    ? true
                                    : false;

                                return (
                                    <button
                                        key={index}
                                        className={`w-full h-12 flex items-center border-l-2 ${
                                            active
                                                ? "border-darkhighlight text-darkhighlight bg-darkbg"
                                                : "text-darkaccent border-dark"
                                        }`}
                                    >
                                        <item.icon className="w-5 h-5 mx-5"></item.icon>
                                        <p className="font-normal">
                                            {item.text}
                                        </p>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                    <div className="grow px-7 pb-28 bg-offwhite h-auto">
                        {/* Navbar */}
                        <div className="mt-7 w-full h-10 mb-10">
                            <div className="flex items-center justify-between w-full h-full">
                                <p className="text-xl text-center text-gray-900">
                                    Overview
                                </p>
                                <Menu as="div" className="relative z-10">
                                    <Menu.Button className="group flex items-center self-end gap-3 text-gray-900 outline-none">
                                        <div
                                            className={`overflow-hidden flex items-center justify-around`}
                                        >
                                            {user.username}
                                            {/* <BiChevronDown className={`text-2xl transform ${isOpen ? 'rotate-180' : 'rotate-0'} duration-200 ease-out`} /> */}
                                        </div>
                                        <div className="group-focus:border-primary flex items-center justify-around w-10 h-10 font-semibold text-white duration-100 border-2 border-gray-300 rounded-full">
                                            <div className="bg-primary flex items-center justify-around w-8 h-8 font-semibold text-white rounded-full select-none">
                                                {user.username.slice(0, 1)}
                                            </div>
                                        </div>
                                    </Menu.Button>
                                    <Transition
                                        beforeEnter={() => setOpen(true)}
                                        enter="transition duration-200 ease-out"
                                        enterFrom="transform -translate-y-4 opacity-0"
                                        enterTo="transform translate-y-0 opacity-100"
                                        leave="transition duration-200 ease-in"
                                        leaveFrom="transform translate-y-0 opacity-100"
                                        leaveTo="transform -translate-y-4 opacity-0"
                                        beforeLeave={() => setOpen(false)}
                                    >
                                        <Menu.Items
                                            className={`absolute right-0 my-4 rounded outline-none`}
                                        >
                                            <div className="w-36 align-right border-primary flex flex-col content-end h-auto gap-1 p-1 bg-white border rounded-md shadow-lg">
                                                <DropdownItem text="Deposit" />
                                                <DropdownItem text="Withdraw" />
                                                <DropdownItem text="View Ledger" />
                                                <DropdownItem text="Account" />
                                                <DropdownItem
                                                    text="Logout"
                                                    type="danger"
                                                    clickEvent={() =>
                                                        navigate("/logout")
                                                    }
                                                />
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        {/* Content */}
                        {childDashboard()}
                    </div>
                </div>
                <div className="grow bg-offwhite place-self-stretch"></div>
            </div>
        );
    }

    return (
        <div>
            <ProtectedRoute />
        </div>
    );

    function childDashboard() {
        return (
            <div className="flex items-center h-auto">
                <div className="gap-7 flex flex-col w-full">
                    {/* Balances */}
                    <div className="gap-7 h-28 grid w-full grid-cols-4">
                        <button className="hover:border-primary group focus:border-primary focus:ring-2 ring-primary ring-opacity-50 flex items-center justify-center w-full h-full transition-shadow bg-white border border-gray-200 rounded-md">
                            <div>
                                <p className="group-hover:text-primary text-base text-center text-gray-400">
                                    Savings
                                </p>
                                <p
                                    className={`mx-auto my-2 text-3xl ${
                                        savingsBalance >= 0
                                            ? "text-gray-900"
                                            : "text-red-500"
                                    } group-hover:text-primary`}
                                >
                                    {formatMoney(savingsBalance)}
                                </p>
                            </div>
                        </button>
                        <button className="hover:border-primary group focus:ring-2 focus:border-primary ring-primary ring-opacity-50 flex items-center justify-center w-full h-full transition-shadow bg-white border border-gray-200 rounded-md">
                            <div>
                                <p className="group-hover:text-primary text-base text-center text-gray-400">
                                    Personal
                                </p>
                                <p
                                    className={`mx-auto my-2 text-3xl ${
                                        personalBalance >= 0
                                            ? "text-gray-900"
                                            : "text-red-500"
                                    } group-hover:text-primary`}
                                >
                                    {formatMoney(personalBalance)}
                                </p>
                            </div>
                        </button>
                        <button className="hover:border-primary group focus:ring-2 focus:border-primary ring-primary ring-opacity-50 flex items-center justify-center w-full h-full transition-shadow bg-white border border-gray-200 rounded-md">
                            <div>
                                <p className="group-hover:text-primary text-base text-center text-gray-400">
                                    Donation
                                </p>
                                <p
                                    className={`mx-auto my-2 text-3xl ${
                                        donationBalance >= 0
                                            ? "text-gray-900"
                                            : "text-red-500"
                                    } group-hover:text-primary`}
                                >
                                    {formatMoney(donationBalance)}
                                </p>
                            </div>
                        </button>
                        <div className=" flex items-center justify-center w-full h-full bg-white border border-gray-200 rounded-md">
                            <div>
                                <p className="group-hover:text-primary text-base text-center text-gray-400">
                                    Total
                                </p>
                                <p
                                    className={`mx-auto my-2 text-3xl ${
                                        savingsBalance +
                                            personalBalance +
                                            donationBalance >=
                                        0
                                            ? "text-gray-900"
                                            : "text-red-500"
                                    } group-hover:text-primary`}
                                >
                                    {formatMoney(
                                        savingsBalance +
                                            personalBalance +
                                            donationBalance
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Recent transactions */}
                    <div className="py-7 flex flex-col w-full bg-white border border-gray-200 rounded-md">
                        <div className="h-14 shrink-0 px-7 flex items-center justify-between w-full mb-2">
                            <div className="flex flex-col justify-between">
                                <p className="text-lg text-gray-900">
                                    Your recent transactions
                                </p>
                                {/* <p className="text-xs font-normal text-gray-400">as of 18 April 2022, 1:29 PM</p> */}
                            </div>
                            <div>
                                <button
                                    onClick={() => setVisibleAddBar(true)}
                                    className="text-primary hover:underline focus:underline flex items-center text-sm"
                                >
                                    <FaPlus className="m-1"></FaPlus>
                                    Add
                                </button>
                            </div>
                        </div>
                        <div className="h-9 px-7 grid items-center w-full grid-cols-12 gap-4">
                            <p className="col-span-5 text-sm text-gray-400">
                                Description
                            </p>
                            <p className="col-span-2 text-sm text-right text-gray-400">
                                Total
                            </p>
                            <p className="col-span-2 text-sm text-gray-400">
                                Account
                            </p>
                            <p className="col-span-3 text-sm text-gray-400">
                                Date
                            </p>
                        </div>
                        <div
                            className={`w-full bg-gray-100 border-gray-200 border-t`}
                        >
                            <form
                                id="transactionForm"
                                className={`${
                                    visibleAddBar ? "" : "hidden"
                                } px-7 w-full h-20 border-b bg-white border-gray-200 grid grid-cols-12 items-center gap-4`}
                            >
                                <input
                                    onChange={(e) =>
                                        setNewTransactionDescription(
                                            e.target.value
                                        )
                                    }
                                    className="bg-gray-50 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 flex items-center w-full h-10 col-span-5 px-4 text-sm transition-shadow border border-gray-200 rounded-md"
                                    maxLength={90}
                                    placeholder="Enter a description"
                                />
                                <div className="flex items-center w-full h-10 col-span-2">
                                    <div className="shrink-0 rounded-l-md flex items-center justify-center w-10 h-10 text-sm bg-gray-200 border border-gray-200">
                                        $
                                    </div>
                                    <input
                                        onChange={(e) => handleMoneyInput(e)}
                                        className="bg-gray-50 rounded-r-md hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 w-full h-full px-2 text-sm text-right transition-shadow border border-gray-200"
                                        placeholder="0.00"
                                    />
                                </div>
                                <Listbox
                                    value={newTransactionAccount}
                                    onChange={setNewTransactionAccount}
                                >
                                    <div className="relative col-span-2">
                                        <Listbox.Button className="bg-gray-50 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 relative flex items-center w-full h-10 px-2 transition-shadow border border-gray-200 rounded-md">
                                            <p className="text-sm text-left">
                                                {newTransactionAccount}
                                            </p>
                                            <BiChevronDown
                                                className={`${
                                                    isListboxOpen
                                                        ? "rotate-180"
                                                        : "rotate-0"
                                                } duration-200 ease-out text-xl ml-auto`}
                                            />
                                        </Listbox.Button>
                                        <Transition
                                            beforeEnter={() =>
                                                setListboxOpen(true)
                                            }
                                            enter="transition duration-200 ease-out"
                                            enterFrom="transform opacity-0"
                                            enterTo="transform opacity-100"
                                            leave="transition duration-200 ease-in"
                                            leaveFrom="transform opacity-100"
                                            leaveTo="transform opacity-0"
                                            beforeLeave={() =>
                                                setListboxOpen(false)
                                            }
                                        >
                                            <Listbox.Options className="absolute w-full mt-3 rounded outline-none">
                                                <div className="align-right border-primary flex flex-col content-end w-full h-auto gap-1 p-1 bg-white border rounded-md shadow-lg">
                                                    <SelectorItem
                                                        currentData={
                                                            newTransactionAccount
                                                        }
                                                        text="Savings"
                                                        data="Savings"
                                                    />
                                                    <SelectorItem
                                                        currentData={
                                                            newTransactionAccount
                                                        }
                                                        text="Personal"
                                                        data="Personal"
                                                    />
                                                    <SelectorItem
                                                        currentData={
                                                            newTransactionAccount
                                                        }
                                                        text="Donation"
                                                        data="Donation"
                                                    />
                                                </div>
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </Listbox>
                                <div className="col-span-2 text-sm">
                                    <p className="whitespace-nowrap text-gray-800">
                                        {unixMsStringToDate(Date.now())[0]}
                                    </p>
                                    <p className="whitespace-nowrap font-normal text-gray-400">
                                        {unixMsStringToDate(Date.now())[1]}
                                    </p>
                                </div>
                                <div className="flex items-center justify-end col-span-1 gap-2">
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            console.log("submitted");
                                            const newTransaction = {
                                                total: newTransactionTotal,
                                                account: newTransactionAccount,
                                                date: Date.now(),
                                                description:
                                                    newTransactionDescription,
                                                id: Date.now(),
                                            };
                                            (async () => {
                                                const newLedger = [
                                                    ...user.ledger,
                                                    newTransaction,
                                                ];
                                                setUser({
                                                    ...user,
                                                    ledger: newLedger,
                                                });
                                                setVisibleAddBar(false);
                                                setLedger(newLedger);
                                            })();
                                        }}
                                        className={`shrink-0 w-10 h-10 rounded-full ml-auto ${
                                            validTransaction
                                                ? "bg-green-200 focus:bg-green-300 hover:bg-green-300 border-green-500"
                                                : "bg-gray-100"
                                        } flex items-center justify-center focus:border`}
                                        disabled={!validTransaction}
                                    >
                                        <FaCheck
                                            className={`${
                                                validTransaction
                                                    ? "text-green-500"
                                                    : "text-gray-500"
                                            } text-lg`}
                                            disabled={!validTransaction}
                                        />
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setVisibleAddBar(false);
                                        }}
                                        className={`shrink-0 w-10 h-10 rounded-full ml-auto bg-red-100 focus:bg-red-200 hover:bg-red-200 border-red-500 flex items-center justify-center focus:border`}
                                    >
                                        <FaPlus
                                            className={`text-red-500 text-lg transform rotate-45`}
                                            disabled={!validTransaction}
                                        />
                                    </button>
                                </div>
                            </form>
                            {ledger.length ? (
                                ledger
                                    .sort((a, b) => b.date - a.date)
                                    .map((transaction, index) => (
                                        <div
                                            key={index}
                                            className={`px-7 w-full h-20 border-b bg-white hover:bg-gray-50 border-gray-200 grid grid-cols-12 items-center gap-4`}
                                        >
                                            <p className="line-clamp-2 block w-full col-span-4 text-sm text-gray-800">
                                                {transaction.description}
                                            </p>
                                            <p className="line-clamp-2 block w-full col-span-1 text-sm text-gray-800">
                                                {transaction.author}
                                            </p>
                                            <p
                                                className={`text-right text-sm col-span-2 ${
                                                    transaction.total >= 0
                                                        ? "text-green-500"
                                                        : "text-red-600"
                                                }`}
                                            >
                                                {formatMoney(transaction.total)}
                                            </p>
                                            <p className="col-span-2 text-sm text-gray-800">
                                                {transaction.account}
                                            </p>
                                            <div className="col-span-2 text-sm">
                                                <p className="whitespace-nowrap text-gray-800">
                                                    {
                                                        unixMsStringToDate(
                                                            transaction.date
                                                        )[0]
                                                    }
                                                </p>
                                                <p className="whitespace-nowrap font-normal text-gray-400">
                                                    {
                                                        unixMsStringToDate(
                                                            transaction.date
                                                        )[1]
                                                    }
                                                </p>
                                            </div>
                                            <div className="cols-span-1 flex items-center justify-end">
                                                <Menu
                                                    as="div"
                                                    className="relative"
                                                >
                                                    <Menu.Button className="hover:bg-gray-200 focus:bg-gray-100 focus:border flex items-center justify-center w-10 h-10 ml-auto border-gray-200 rounded-full">
                                                        <HiDotsVertical className="text-lg text-gray-500" />
                                                    </Menu.Button>
                                                    <Transition
                                                        beforeEnter={() =>
                                                            setOpen(true)
                                                        }
                                                        enter="transition duration-100 ease-out"
                                                        enterFrom="transform -translate-y-1 opacity-0"
                                                        enterTo="transform translate-y-0 opacity-100"
                                                        leave="transition duration-100 ease-in"
                                                        leaveFrom="transform translate-y-0 opacity-100"
                                                        leaveTo="transform -translate-y-1 opacity-0"
                                                        beforeLeave={() =>
                                                            setOpen(false)
                                                        }
                                                    >
                                                        <Menu.Items
                                                            className={`absolute right-0 rounded outline-none`}
                                                        >
                                                            <div className="align-right flex flex-col content-end w-40 h-auto gap-1 p-1 bg-white border border-red-600 rounded-md shadow-lg">
                                                                <DropdownItem
                                                                    text="Permanently delete"
                                                                    type="danger"
                                                                    clickEvent={() => {
                                                                        console.log(
                                                                            index
                                                                        );
                                                                        console.log(
                                                                            transaction.id
                                                                        );
                                                                        const newLedger =
                                                                            ledger.filter(
                                                                                (
                                                                                    ledgerTransaction
                                                                                ) =>
                                                                                    transaction.id !==
                                                                                    ledgerTransaction.id
                                                                            );
                                                                        setUser(
                                                                            {
                                                                                ...user,
                                                                                ledger: newLedger,
                                                                            }
                                                                        );
                                                                        setLedger(
                                                                            newLedger
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="px-7 flex items-center justify-center w-full h-20 gap-4 bg-gray-100 border-b border-gray-200">
                                    <p className="text-sm text-gray-400">
                                        You haven't made any transactions
                                        recently...
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="gap-7 grid w-full grid-cols-2">
                        <div className="py-7 flex flex-col bg-white border border-gray-200 rounded-md">
                            <div className="h-14 shrink-0 px-7 flex items-center justify-between w-full mb-2">
                                <div className="flex flex-col justify-between">
                                    <p className="text-lg text-gray-900">
                                        Calendar
                                    </p>
                                </div>
                            </div>
                            <div className="h-9 px-7 grid items-center w-full grid-cols-12 gap-4">
                                <p className="col-span-4 text-sm text-gray-400">
                                    Percent
                                </p>
                                <p className="col-span-4 text-sm text-gray-400">
                                    Date
                                </p>
                                <p
                                    className={`text-sm col-span-3 text-gray-400`}
                                >
                                    Earned
                                </p>
                            </div>
                            <div
                                className={`${
                                    msTimestampWithinCurrentWeek(
                                        workDaySelected
                                    )
                                        ? ""
                                        : ""
                                } px-7 w-full h-20 border-t bg-white border-gray-200 grid grid-cols-12 items-center gap-4`}
                            >
                                <input
                                    onChange={(e) =>
                                        setNewTransactionDescription(
                                            e.target.value
                                        )
                                    }
                                    className="bg-gray-50 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 flex items-center hidden w-full h-10 col-span-4 px-4 text-sm transition-shadow border border-gray-200 rounded-md"
                                    maxLength={90}
                                    placeholder="Enter a description"
                                />
                                <div className="relative col-span-4">
                                    <div className="relative flex items-center w-full h-10">
                                        {console.log(workCreditDropdown)}
                                        <p className="text-sm text-left">
                                            {workCreditDropdown?.message || ""}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-span-4 text-sm">
                                    <p className="whitespace-nowrap text-gray-800">
                                        {
                                            weekdays[
                                                new Date(
                                                    workDaySelected
                                                ).getDay()
                                            ]
                                        }
                                    </p>
                                    <p className="whitespace-nowrap font-normal text-gray-400">
                                        {unixMsStringToDate(workDaySelected)[0]}
                                    </p>
                                </div>
                                <div className="flex items-center w-11/12 h-10 col-span-3">
                                    <div>
                                        {formatMoney(
                                            workCreditDollars *
                                                (workCreditDropdown?.percent /
                                                    100)
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="h-9 flex items-center justify-between w-full gap-4 border-t border-gray-200">
                                <button
                                    onClick={(e) => {
                                        let month = parseInt(
                                            msMonth.split(".")[0]
                                        );
                                        let year = parseInt(
                                            msMonth.split(".")[1]
                                        );
                                        month -= 1;
                                        if (month < 0) {
                                            month = 11;
                                            year -= 1;
                                        }
                                        let d = new Date();
                                        d.setFullYear(year);
                                        d.setMonth(month);
                                        d.getDate(0);
                                        d.setMilliseconds(0);
                                        setMsMonthStart(d.getTime());
                                        setMsMonth(`${month}.${year}`);
                                    }}
                                    className="h-9 shrink-0 hover:text-primary focus:text-primary active:bg-gray-100 flex items-center justify-center w-12 text-lg text-gray-400 border-r border-gray-200"
                                >
                                    <FaCaretLeft></FaCaretLeft>
                                </button>
                                <p className="grow flex items-center justify-center h-full text-gray-900">{`${
                                    months[parseInt(msMonth.split(".")[0])]
                                } ${msMonth.split(".")[1]}`}</p>
                                <button
                                    onClick={(e) => {
                                        let month = parseInt(
                                            msMonth.split(".")[0]
                                        );
                                        let year = parseInt(
                                            msMonth.split(".")[1]
                                        );
                                        month += 1;
                                        if (month > 11) {
                                            month = 0;
                                            year += 1;
                                        }
                                        let d = new Date();
                                        d.setFullYear(year);
                                        d.setMonth(month);
                                        d.getDate(0);
                                        d.setMilliseconds(0);
                                        setMsMonthStart(d.getTime());
                                        setMsMonth(`${month}.${year}`);
                                    }}
                                    className="h-9 shrink-0 hover:text-primary focus:text-primary active:bg-gray-100 flex items-center justify-center w-12 text-lg text-gray-400 border-l border-gray-200"
                                >
                                    <FaCaretRight></FaCaretRight>
                                </button>
                            </div>
                            <div className="bg-gray-50 border-y px-14 w-full border-gray-200">
                                <div className="grid w-full grid-cols-7 text-gray-400">
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        Su
                                    </p>
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        Mo
                                    </p>
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        Tu
                                    </p>
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        We
                                    </p>
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        Th
                                    </p>
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        Fr
                                    </p>
                                    <p className="flex items-center justify-center w-full h-10 col-span-1 px-1 text-sm">
                                        Sa
                                    </p>
                                </div>
                                <div className="grid w-full grid-cols-7">
                                    {Array(42)
                                        .fill()
                                        .map((item, i) => {
                                            const selfDate = getCalendarDate(
                                                msMonthStart,
                                                i
                                            );
                                            const today = new Date(Date.now());
                                            const isToday =
                                                today.getFullYear() ===
                                                    selfDate.year &&
                                                today.getMonth() ===
                                                    selfDate.month &&
                                                today.getDate() ===
                                                    selfDate.date;
                                            let selfDateInMS = new Date(
                                                Date.now()
                                            ).setFullYear(
                                                selfDate.year,
                                                selfDate.month,
                                                selfDate.date
                                            );

                                            const work = workCredit.find(
                                                (workI: any) => {
                                                    const [date, month, year] =
                                                        workI.id.split(".");
                                                    return (
                                                        parseInt(date) ===
                                                            selfDate.date &&
                                                        parseInt(month) ===
                                                            selfDate.month &&
                                                        parseInt(year) ===
                                                            selfDate.year
                                                    );
                                                }
                                            );
                                            if (!work) {
                                                return (
                                                    <button
                                                        onClick={() => {
                                                            setWorkDaySelected(
                                                                selfDateInMS
                                                            );
                                                            const selfId = `${selfDate.date}.${selfDate.month}.${selfDate.year}`;
                                                            const selfData =
                                                                workCredit.find(
                                                                    (
                                                                        workI: any
                                                                    ) =>
                                                                        workI.id ===
                                                                        selfId
                                                                ) || {
                                                                    id: selfId,
                                                                    earnable:
                                                                        workCredit.sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) => {
                                                                                return (
                                                                                    parseInt(
                                                                                        `${
                                                                                            b.id.split(
                                                                                                "."
                                                                                            )[2]
                                                                                        }${
                                                                                            b.id.split(
                                                                                                "."
                                                                                            )[2]
                                                                                        }${
                                                                                            b.id.split(
                                                                                                "."
                                                                                            )[0]
                                                                                        }`
                                                                                    ) -
                                                                                    parseInt(
                                                                                        `${
                                                                                            a.id.split(
                                                                                                "."
                                                                                            )[2]
                                                                                        }${
                                                                                            a.id.split(
                                                                                                "."
                                                                                            )[2]
                                                                                        }${
                                                                                            a.id.split(
                                                                                                "."
                                                                                            )[0]
                                                                                        }`
                                                                                    )
                                                                                );
                                                                            }
                                                                        )[0]
                                                                            ?.earnable ||
                                                                        5.0,
                                                                    note: "",
                                                                };
                                                            console.log(
                                                                selfData,
                                                                selfId
                                                            );
                                                            setWorkCreditDollars(
                                                                selfData
                                                                    ? selfData.earnable
                                                                    : 0
                                                            );
                                                            const work =
                                                                new Map();
                                                            work.set(0, {
                                                                message:
                                                                    "No credit (0%)",
                                                                percent: 0,
                                                            });
                                                            work.set(50, {
                                                                message:
                                                                    "Half day (50%)",
                                                                percent: 50,
                                                            });
                                                            work.set(100, {
                                                                message:
                                                                    "Full day (100%)",
                                                                percent: 100,
                                                            });
                                                            work.set(120, {
                                                                message:
                                                                    "Overtime (120%)",
                                                                percent: 120,
                                                            });
                                                            setWorkCreditDropdown(
                                                                selfData.percent
                                                                    ? work.get(
                                                                          selfData.percent
                                                                      )
                                                                    : msTimestampWithinCurrentWeek(
                                                                          selfDateInMS
                                                                      )
                                                                    ? ""
                                                                    : {
                                                                          message:
                                                                              "Not applicable",
                                                                          percent:
                                                                              null,
                                                                      }
                                                            );
                                                        }}
                                                        key={i}
                                                        className="group p-1"
                                                        disabled={
                                                            !selfDate.insideMonth ||
                                                            Date.now() <
                                                                selfDateInMS
                                                        }
                                                    >
                                                        <div
                                                            className={`mx-auto ${
                                                                selfDateInMS ===
                                                                workDaySelected
                                                                    ? "border"
                                                                    : ""
                                                            } border-primary col-span-1 h-10 w-10 ${
                                                                selfDate.insideMonth
                                                                    ? isToday
                                                                        ? "border border-primary text-primary"
                                                                        : "text-gray-600"
                                                                    : "text-gray-300"
                                                            } group-hover:bg-gray-200 rounded-full flex items-center justify-center text-sm`}
                                                        >
                                                            {selfDate.date}
                                                        </div>
                                                    </button>
                                                );
                                            }
                                            return (
                                                <button
                                                    key={i}
                                                    onClick={() => {
                                                        setWorkDaySelected(
                                                            selfDateInMS
                                                        );
                                                        const selfId = `${selfDate.date}.${selfDate.month}.${selfDate.year}`;
                                                        const selfData =
                                                            workCredit.find(
                                                                (workI: any) =>
                                                                    workI.id ===
                                                                    selfId
                                                            ) || {
                                                                id: selfId,
                                                                earnable:
                                                                    workCredit.sort(
                                                                        (
                                                                            a,
                                                                            b
                                                                        ) => {
                                                                            return (
                                                                                parseInt(
                                                                                    `${
                                                                                        b.id.split(
                                                                                            "."
                                                                                        )[2]
                                                                                    }${
                                                                                        b.id.split(
                                                                                            "."
                                                                                        )[2]
                                                                                    }${
                                                                                        b.id.split(
                                                                                            "."
                                                                                        )[0]
                                                                                    }`
                                                                                ) -
                                                                                parseInt(
                                                                                    `${
                                                                                        a.id.split(
                                                                                            "."
                                                                                        )[2]
                                                                                    }${
                                                                                        a.id.split(
                                                                                            "."
                                                                                        )[2]
                                                                                    }${
                                                                                        a.id.split(
                                                                                            "."
                                                                                        )[0]
                                                                                    }`
                                                                                )
                                                                            );
                                                                        }
                                                                    )[0]
                                                                        ?.earnable ||
                                                                    5.0,
                                                                note: "",
                                                            };
                                                        console.log(
                                                            selfData,
                                                            selfId
                                                        );
                                                        setWorkCreditDollars(
                                                            selfData
                                                                ? selfData.earnable
                                                                : 0
                                                        );
                                                        const work = new Map();
                                                        work.set(0, {
                                                            message:
                                                                "No credit (0%)",
                                                            percent: 0,
                                                        });
                                                        work.set(50, {
                                                            message:
                                                                "Half day (50%)",
                                                            percent: 50,
                                                        });
                                                        work.set(100, {
                                                            message:
                                                                "Full day (100%)",
                                                            percent: 100,
                                                        });
                                                        work.set(120, {
                                                            message:
                                                                "Overtime (120%)",
                                                            percent: 120,
                                                        });
                                                        setWorkCreditDropdown(
                                                            selfData.percent !==
                                                                undefined
                                                                ? work.get(
                                                                      selfData.percent
                                                                  )
                                                                : msTimestampWithinCurrentWeek(
                                                                      selfDateInMS
                                                                  )
                                                                ? ""
                                                                : {
                                                                      message:
                                                                          "Not applicable",
                                                                      percent:
                                                                          null,
                                                                  }
                                                        );
                                                    }}
                                                    className="group p-1"
                                                    disabled={
                                                        !selfDate.insideMonth ||
                                                        Date.now() <
                                                            selfDateInMS
                                                    }
                                                >
                                                    <div
                                                        className={`mx-auto ${
                                                            selfDateInMS ===
                                                            workDaySelected
                                                                ? "border"
                                                                : ""
                                                        } border-primary col-span-1 h-10 w-10 ${
                                                            work.percent < 25
                                                                ? "bg-red-300 group-hover:bg-red-400"
                                                                : work.percent <
                                                                  100
                                                                ? "bg-yellow-300 group-hover:bg-yellow-400"
                                                                : work.percent <
                                                                  120
                                                                ? "bg-green-200 group-hover:bg-green-300"
                                                                : work.percent <
                                                                  200
                                                                ? "bg-blue-300 group-hover:bg-blue-400"
                                                                : isToday
                                                                ? "group-hover:bg-gray-200"
                                                                : "group-hover:bg-gray-200"
                                                        } ${
                                                            selfDate.insideMonth
                                                                ? isToday
                                                                    ? "border border-primary text-primary"
                                                                    : "text-gray-600"
                                                                : "text-gray-300"
                                                        } rounded-full flex items-center justify-center text-sm`}
                                                    >
                                                        {selfDate.date}
                                                    </div>
                                                </button>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
