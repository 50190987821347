// @ts-nocheck
import * as React from 'react';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import { FaAngleRight, FaTimes } from 'react-icons/fa';
import { Logo } from '../../components/Logo';
import { getUserData, loginUser, registerUser } from '../../utils/api';
import { LoadingContext, UserContext } from '../../utils/context';

export const Register = () => {
    const navigate = useNavigate();
    
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [verifyPassword, setVerifyPassword] = React.useState('');
    const [error, toggleError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [registering, setRegistering] = React.useState(false);

    const [user, setUser] = React.useContext(UserContext);
    const [loading, setLoading] = React.useContext(LoadingContext);

    React.useEffect(() => {
        if (user) {
            navigate('/dashboard');
        } else {
            console.log('Not logged in');
        }
    }, []);

    let submitObject = {
        username: "",
        email: "",
        password: ""
    }

    async function submitRegister(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        // console.log({ username: username, email: email, password: password, verifyPassword: verifyPassword });
        if (password !== verifyPassword) {
            setErrorMessage("Your passwords do not match");
            return toggleError(true);
        }

        setRegistering(true);

        try {
            submitObject = { username: username, email: email, password: password };
            console.log(submitObject);
            await registerUser(submitObject)
            .then((res) => {
                console.log(res.data);
                if (res.data.redirect) {
                    loginUser({ username: username, password: password })
                    .then((res) => {
                        console.log(res.data);
                        setUser(res.data);
                        if (res.data.redirect === true) {
                            navigate('/dashboard');
                        }
                        setRegistering(false);
                    });
                } else if (res.data.message.length > 0) {
                    setRegistering(false);
                    setErrorMessage(res.data.message);
                    toggleError(true);
                }
            });
        } catch(err) {
            setRegistering(false);
            setErrorMessage('Connection error');
            toggleError(true);
        }
    }

    return (
        <div className="flex flex-col h-screen bg-dark">
            <div className="grow">
                <div className="flex items-center h-full">
                    <div className="mx-auto my-3 bg-white border border-gray-200 p-10 rounded-lg">
                        <div className="w-full flex flex-col mb-5 items-center">
                            <div>
                                <div className="bg-primary w-14 h-14 rounded-full mx-auto"></div>
                                <h1 className="mt-3 text-lg text-gray-300">Money Manager</h1>
                            </div>

                            <h1 className="mt-3 text-xl text-gray-800">Sign up for Money Manager</h1>
                            <p className="text-lightgray font-normal text-sm my-4 w-full block text-center">Enter your account details below</p>
                        </div>
                        <form className="flex flex-col w-72 gap-5">
                            <div className="w-full">
                                <h1 className="text-lightgray text-xs uppercase">Username</h1>
                                <input type="text" placeholder="Username" name="user" className="text-sm font-normal my-0.5 border-gray-200 hover:border-primary transform focus:border-primary bg-offwhite border rounded-lg h-10 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 px-4 py-2 duration-200 caret-primary" onChange={(e) => {
                                    setUsername(e.target.value);
                                }} />
                            </div>
                            <div className="w-full">
                                <h1 className="text-lightgray text-xs uppercase">Email</h1>
                                <input type="text" placeholder="Email" name="user" className="text-sm font-normal my-0.5 border-gray-200 hover:border-primary transform focus:border-primary bg-offwhite border rounded-lg h-10 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 px-4 py-2 duration-200 caret-primary" onChange={(e) => {
                                    setEmail(e.target.value);
                                }} />
                            </div>
                            <div className="w-full">
                                <h1 className="text-lightgray text-xs uppercase">Password</h1>
                                <input type="password" placeholder="Password" name="user" className="text-sm font-normal my-0.5 border-gray-200 hover:border-primary transform focus:border-primary bg-offwhite border rounded-lg h-10 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 px-4 py-2 duration-200 caret-primary" onChange={(e) => {
                                    setPassword(e.target.value);
                                }} />
                            </div>
                            <div className="w-full">
                                <h1 className="text-lightgray text-xs uppercase">Confirm Password</h1>
                                <input type="password" placeholder="Confirm Password" name="user" className="text-sm font-normal my-0.5 border-gray-200 hover:border-primary transform focus:border-primary bg-offwhite border rounded-lg h-10 w-full ring-opacity-50 ring-primary transition-all focus:ring-2 px-4 py-2 duration-200 caret-primary" onChange={(e) => {
                                    setVerifyPassword(e.target.value);
                                }} />
                            </div>
                        </form>
                        <div className={`mt-5 w-72 rounded-lg bg-offwhite flex ${!error ? `hidden` : ``}`}>
                            <p className="shrink-0 h-8 rounded-l-lg flex items-center px-2 font-normal border border-red-500 text-red-500 text-sm text-ellipsis grow">{errorMessage}</p>
                            <button className="border-t border-b border-r border-gray-200 focus:border-gray-400 hover:bg-gray-200 shrink-0 transform group w-8 bg-gray-100 h-8 flex items-center justify-around rounded-tr-md rounded-br-md" onClick={() => toggleError(false)}>
                                <FaTimes className="text-lightgray group-focus:text-gray-500 group-hover:text-gray-500" />
                            </button>
                        </div>
                        <button onClick={(event) => { submitRegister(event); }} className={`my-5 h-12 w-full bg-primary rounded-lg group focus:ring-2 ring-primary ring-opacity-50 transition-all flex items-center justify-around text-white hover:bg-transparent hover:text-gray-800 border border-primary shadow-lg duration-100 disabled:bg-primary disabled:ring-0 disabled:brightness-75 disabled:transform disabled:text-white`} >
                            <div className="font-normal flex items-center">
                                <h1>Sign Up</h1>
                            </div>
                        </button>
                        <div className="flex mt-10 mx-auto gap-1 justify-center">
                            <p className="text-sm font-normal text-lightgray">Already have an account?</p>
                            <button onClick={() => { navigate('/login'); }} className="font-medium duration-100 text-primary hover:underline focus:underline text-sm ">Log In</button>
                        </div>
                    </div>
                    <div className="absolute bottom-0 my-2 w-screen h-auto flex justify-center">
                        <p className="text-xs w-72 text-center whitespace-pre-wrap text-gray-400">By using this service, you are agreeing to the <a href="/legal/terms" className="font-medium duration-100 text-gray-400 hover:text-gray-200 text-xs focus:text-primary underline">Terms of Service</a> and <a href="/legal/privacy" className="underline font-medium duration-100 text-gray-400 hover:text-gray-200 text-xs focus:text-gray-200">Privacy Policy</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}