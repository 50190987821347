// @ts-nocheck
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserData } from '../utils/api';
import { UserContext } from '../utils/context';
import { iso_url } from '../utils/urls';
import { Logo } from './Logo';
import { MdClose, MdMenu } from 'react-icons/md';
import { ButtonPrimary, ButtonSecondary } from './Buttons';

export const Navbar = (props: { user?: any }) => {
    const navigate = useNavigate();

    const [user, setUser] = React.useContext(UserContext);
    const [menu, toggleMenu] = React.useState(false);

    function getWindowLocation(windowLoc: string, exact?: boolean) {
        // let web_thing = window.location.protocol + '//' + window.location.host;
    
        let win;
        win = window.location.href;
        if (!win) win = '';
        if(exact) {
            if (window.location.pathname === windowLoc) return true;
            return false;
        }
        if (window.location.pathname.startsWith(`${windowLoc}`)) return true;
        return false;
    }

    const pageItems = [
        { text: "Home", redirect: "/", exact: true },
        { text: "Projects", redirect: "/projects", exact: false },
        { text: "Minecraft", redirect: "/mc-lockdown", exact: false },
        { text: "Advance Bot", redirect: "/advance-bot", exact: false }
    ]

    function NavItem(props: {text: string, redirect: string, page: string, exact?: boolean, dropdown?: boolean}) {
        return(
            <button tabIndex={props.dropdown ? menu ? '0' : '-1' : '0'} onClick={() => { navigate(props.redirect); }} className={`text-lg 2md:text-base w-auto shrink-0 font-semibold duration-100 border-b-2 border-t-2 ${getWindowLocation(props.page, (props.exact !== true) ? false : true) ? `text-primary` : ``} border-white hover:border-b-primary focus:border-b-primary shrink`}>{props.text}</button>
        );
    }
    
    return (
        <div>
            <section id="navbar" className="border-b border-gray-300 shrink-0 fixed bg-white w-full shadow-sm">
                <div className={`max-w-7xl mx-auto px-10 duration-200 ease-out overflow-hidden ${menu ? `h-80 2md:h-28` : `h-28`}`}>
                    <div className="flex justify-between h-28 items-center shrink-0">
                        <Logo />
                        <div className="my-3 w-full flex items-center justify-end 2md:hidden gap-3">
                            {!user ?
                                <button onClick={() => { navigate('/login'); }} className="text-base h-8 px-2 border-2 border-primary rounded-md font-semibold group focus:ring-2 ring-primary ring-opacity-50 transition-all items-center bg-primary text-white">
                                    <h1>Login</h1>
                                </button>
                                :
                                <button onClick={() => { navigate('/dashboard'); }} className={`text-base h-10 w-10 px-3 border-2 border-primary rounded-full font-semibold group focus:ring-2 ring-primary ring-opacity-50 transition-all flex items-center bg-primary text-white`}>
                                    <h1>{user.username.slice(0, 1)}</h1>
                                </button>
                            }
                            <button onClick={(e) => { menu ? toggleMenu(false) : toggleMenu(true) }} className="h-10 w-10 flex items-center justify-center text-4xl 2md:hidden"><div className={`transform duration-200 ease-in-out ${menu ? `-scale-y-100` : `scale-y-100`}`}>
                                {
                                    !menu ? <MdMenu></MdMenu> 
                                    :       <MdClose></MdClose>
                                }
                                </div></button>
                        </div>
                        <div className="hidden items-center h-10 gap-5 2md:flex">
                            {pageItems.map((btn) => {
                                return (
                                    <NavItem text={btn.text} redirect={btn.redirect} page={btn.redirect} exact={btn.exact} />
                                );
                            })}
                            {!user ?
                                <div className="flex gap-2">
                                    <ButtonSecondary text="Log In" redirect="/login" small borderless />
                                    <ButtonPrimary text="Sign Up" redirect="/register" small />
                                </div>
                                :
                                <button onClick={() => { navigate('/dashboard'); }} className={`text-base h-10 w-10 px-3 border-2 border-primary rounded-full font-semibold group focus:ring-2 ring-primary ring-opacity-50 transition-all flex items-center bg-primary text-white`}>
                                    <h1>{user.username.slice(0, 1)}</h1>
                                </button>
                            }
                        </div>
                    </div>
                    <div className="2-full h-72 shrink-0 flex flex-col">
                        {pageItems.map((btn) => {
                            return (
                                <div className="my-1 w-full flex items-center justify-end">
                                    <NavItem text={btn.text} redirect={btn.redirect} page={btn.redirect} exact={btn.exact} dropdown />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <div className=" shrink-0 h-24 w-full" />
        </div>
    );
}