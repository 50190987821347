import axios from 'axios';
import { api_url } from '../utils/urls';

export async function registerUser(registerForm: Object) {
    return axios.post(`${api_url}/api/auth/register`, registerForm);
}

export async function loginUser(loginForm: Object) {
    return axios.post(`${api_url}/api/auth/login`, loginForm);
}

export function getUserData() {
    return axios.get(`${api_url}/api/auth/`, { withCredentials: true });
}

export function logoutUser() {
    return axios.get(`${api_url}/api/auth/logout`, { withCredentials: true });
}

export function getUsers() {
    return axios.get(`${api_url}/api/users/list`, { withCredentials: true });
}

export function getUser(userId: String) {
    return axios.get(`${api_url}/api/users/data/info/${userId}`, { withCredentials: true });
}

export function getChildrenData() {
    return axios.get(`${api_url}/api/users/data/children`, { withCredentials: true });
}

export function updateUser(userId: String, updatedUserData: any) {
    return axios.post(`${api_url}/api/users/data/${userId}/update`, updatedUserData, { withCredentials: true });
}

export function deleteUser(userId: String) {
    return axios.post(`${api_url}/api/users/data/${userId}/delete`, {confirmed: true}, { withCredentials: true });
}

export function addLedgerItem(userId: String, newElement: Object) {
    return axios.post(`${api_url}/api/users/ledger/add/${userId}`, { newTransaction: newElement}, { withCredentials: true });
}

export function removeLedgerItem(userId: String, elementId: any) {
    return axios.post(`${api_url}/api/users/ledger/remove/${userId}`, { transactionId: elementId }, { withCredentials: true });
}

export function addWorkItem(userId: String, newElement: Object) {
    return axios.post(`${api_url}/api/users/work/add/${userId}`, { newWork: newElement }, { withCredentials: true });
}

export function removeWorkItem(userId: String, elementId: any) {
    return axios.post(`${api_url}/api/users/work/remove/${userId}`, { transactionId: elementId }, { withCredentials: true });
}