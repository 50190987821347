// @ts-nocheck
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProtectedRoute } from '../../components/ProtectedRoute';
import { Navbar } from '../../components/Navbar';
import '../../index.css';
import { LoadingContext, UserContext } from '../../utils/context';
import { addLedgerItem, removeLedgerItem, getChildrenData, addWorkItem } from '../../utils/api';
import { Menu, Transition, Listbox } from '@headlessui/react'
import { BiChevronDown } from 'react-icons/bi';
import { FaMinus, FaPlus, FaCheck, FaPlug, FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';

export const DashboardParent = () => {
    const navigate = useNavigate();

    const [user, setUser] = React.useContext(UserContext);
    const [loading, setLoading] = React.useContext(LoadingContext);
    const [isOpen, setOpen] = React.useState(false);
    const [isListboxOpen, setListboxOpen] = React.useState(false);

    const [newTransactionDescription, setNewTransactionDescription] = React.useState('');
    const [newTransactionTotal, setNewTransactionTotal] = React.useState(0.00);
    const [newTransactionAccount, setNewTransactionAccount] = React.useState('');
    const [validTransaction, setValidTransaction] = React.useState(false);
    
    React.useEffect(() => {
        if (!user) {
            console.log('Not logged in');
            navigate('/login');   
        }
    }, []);

    if (!user.parentAccount) {
        navigate('/dashboard');
    }

    const accountTypes = ['Personal', 'Savings', 'Donation'];

    function unixMsStringToDate(str: string) {
        let d = new Date(0);
        d.setUTCSeconds(Math.floor(parseInt(str)/1000));
        return formatDate(d);
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thurday', 'Friday', 'Saturday'];
    function formatDate(d: Date) {
        return [`${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`, `${d.toLocaleTimeString().split(':')[0]}:${d.toLocaleTimeString().split(':')[1]} ${d.toLocaleTimeString().slice(-2)}`];
    }

    // create a function with a callback function as an argument


    const DropdownItem = (props: { text: string, disabled?: boolean | false, type?: 'danger', redirect?: string, clickEvent?: Function}) => {
        return (
            <Menu.Item disabled={props.disabled}>
                {({ active, disabled }) => (
                    <button onClick={() => props.clickEvent ? props.clickEvent() : null} className={`w-full py-1.5 rounded text-left ${active && props.type === undefined ? 'bg-gray-200' : active ? 'bg-red-200' : null} ${disabled ? 'text-gray-600' : null} ${props.type === 'danger' ? 'text-red-600' : 'text-gray-700'} outline-none text-sm flex items-center px-2`}>
                        {props.text}
                    </button>
                )}</Menu.Item>
            // <button className={`w-full py-1.5 rounded text-left hover:bg-gray-800 ${props.type === 'danger' ? 'text-red-500' : 'text-gray-400'} text-sm flex items-center px-2`}>
            //     {props.text}
            // </button>
        );
    }

    const SelectorItem = (props: { currentData?: any, text: string, disabled?: boolean | false, type?: 'danger', data?: any}) => {
        return (
            <Listbox.Option disabled={props.disabled} value={props.data || props.text}>
                {({ active, disabled }) => (
                    <button className={`w-full py-1.5 rounded text-left ${props.currentData === props.data ? 'text-primary' : 'text-gray-700'} ${active && props.type === undefined ? 'bg-gray-200' : active ? 'bg-red-200' : null} ${disabled ? 'text-gray-600' : null} ${props.type === 'danger' ? 'text-red-600' : null} outline-none text-sm flex items-center px-2`}>
                        {props.text}
                    </button>
                )}</Listbox.Option>
            // <button className={`w-full py-1.5 rounded text-left hover:bg-gray-800 ${props.type === 'danger' ? 'text-red-500' : 'text-gray-400'} text-sm flex items-center px-2`}>
            //     {props.text}
            // </button>
        );
    }

    // make a function that will convert any number like 1000 to 1.0K or 1000000 to 1.0M and so on
    function formatMoney(amount: number) {
        if (amount >= 1000000000000) {
            return `$$$`;
        } else if (amount >= 1000000000) {
            return `$${(amount / 1000000000).toFixed(3)}B`;
        } else if (amount >= 100000) {
            return `$${(amount/1000000).toFixed(3)}M`;
        } else {
            // convert any number as a human readable string with commas and decimal places and if the number is negative, have the negative sign in front
            if (amount < 0) {
                return `-$${(Math.abs(amount)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            } else {
                return `$${(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
            }
        }
    }

    // make a function that gets the unix timestamp for the start of the current month
    function getStartOfMonth() {
        let d = new Date();
        d.setDate(1);
        d.setUTCMilliseconds(0);
        return d.getTime();
    }

    function handleMoneyInput(e, updateValue: Function, negative?: boolean) {
        e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
        if (e.target.value.includes('-') && negative !== false) {
            e.target.value = e.target.value.replace(/[-]/g, '');
            e.target.value = `-${e.target.value}`;
        } else {
            e.target.value = e.target.value.replace(/[-]/g, '');
        }
        if (e.target.value.includes('.')) {
            let left = parseInt(e.target.value.split('.')[0]);
            if (isNaN(left)) left = '';
            e.target.value = left + '.' + e.target.value.split('.')[1].slice(0, 2);
        } else {
            e.target.value = e.target.value.replace(/[- ]/g, '').length ? parseInt(e.target.value) : '';
        }
        if (e.target.value.replace(/[- ]/g, '').length === 0) {
            updateValue(0);
        } else {
            updateValue(parseFloat(e.target.value));
        }
    }
    

    const [ledger, setLedgerHere] = React.useState(user.ledger);

    // create a function that filters the ledger by account type and then adds up the totals for each account type
    function filterLedger(type: string, _ledger: Array) {
        let filteredLedger = _ledger.filter(item => item.account === type);
        let total = 0;
        filteredLedger.forEach(item => {
            total += item.total;
        });
        return total;
    }

    const [personalBalance, setPersonalBalance] = React.useState(filterLedger('Personal', ledger));
    const [savingsBalance, setSavingsBalance] = React.useState(filterLedger('Savings', ledger));
    const [donationBalance, setDonationBalance] = React.useState(filterLedger('Donation', ledger));
    const [visibleAddBar, setVisibleAddBar] = React.useState(false);

    // React.useEffect(() => {
    //     setPersonalBalance(filterLedger('Personal'));
    //     setSavingsBalance(filterLedger('Savings'));
    //     setDonationBalance(filterLedger('Donation'));
    // }, [ledger]);

    function setLedger(newLedger: any) {
        try {
            newLedger = newLedger.sort((a, b) => {
                return b.date - a.date;
            });
        } catch (e) {
            console.log(e);
        }
        setLedgerHere(newLedger);
        setPersonalBalance(filterLedger('Personal', newLedger));
        setSavingsBalance(filterLedger('Savings', newLedger));
        setDonationBalance(filterLedger('Donation',newLedger));
    }

    React.useEffect(() => {
        // if the transaction total is not 0 and newTransactionAccount is equal to 'Personal' or 'Savings' or 'Donation' and the newTransactionDescription is not empty, then set validTransaction to true
        if (newTransactionTotal !== 0 && (newTransactionAccount === 'Personal' || newTransactionAccount === 'Savings' || newTransactionAccount === 'Donation') && newTransactionDescription !== '') {
            setValidTransaction(true);
        } else {
            setValidTransaction(false);
        }
    }, [newTransactionAccount, newTransactionTotal, newTransactionDescription]);

    const sidebar = [
        { icon: HiDotsVertical, text: 'Overview', redirect: '/dashboard' },
        {icon: HiDotsVertical, text: 'Accounts', redirect: '/dashboard/accounts'},
        {icon: HiDotsVertical, text: 'Transactions', redirect: '/dashboard/transactions'},
        {icon: HiDotsVertical, text: 'Budget', redirect: '/dashboard/budget'},
        {icon: HiDotsVertical, text: 'Reports', redirect: '/dashboard/reports'},
        {icon: HiDotsVertical, text: 'Settings', redirect: '/dashboard/settings'},
    ]

    const [currency, setCurrency] = React.useState(0.00);

    // create a function to calculate how many sundays between two dates
    function calculateSundaysBetweenDates(startDate: number, endDate: number) {
        let sundays = 0;
        let currentDate = startDate;
        while (currentDate <= endDate) {
            if (new Date(currentDate).getDay() === 0) {
                sundays++;
            }
            currentDate += 86400000;
        }
        return sundays;
    }
    // create a function to determine what day of the week a month starts on
    function getMonthStartDay(date: number) {
        let d = new Date(date);
        d.setDate(1);
        return d.getDay();
    }

    // create a function to determine how many days are in a month
    function getDaysInMonth(date: number) {
        let d = new Date(date);
        d.setDate(1);
        d.setMonth(d.getMonth() + 1);
        d.setDate(0);
        return d.getDate();
    }

    function getDayOfMonth(date: number) {
        let d = new Date(date);
        return d.getDate();
    }

    function getMsStartOfMonth(date: number) {
        let d = new Date(date);
        d.setDate(1);
        d.setMilliseconds(0);
        date = d.getTime();
        return (date);
    }

    function getCalendarDate(date: number, calendarPosition: number) {
        // get the millisecond beginning of the month
        date = getMsStartOfMonth(date);
        const dayMs = 86400000;
        // get the start day of the month (monday - friday)
        const startDay = getMonthStartDay(date);
        const msDay = (calendarPosition - startDay) * dayMs;
        // get the day of the month of msDay
        const dayOfMonth = getDayOfMonth(date + msDay);
        const month = new Date(date + msDay).getMonth();
        const year = new Date(date + msDay).getFullYear();
        
        return { insideMonth: new Date(date + msDay).getMonth() === new Date(date).getMonth(), date: dayOfMonth, month: month, year: year};
    }
    
    let d = new Date(Date.now());
    const [msMonth, setMsMonth] = React.useState(`${d.getMonth()}.${d.getFullYear()}`);
    let setThisMonth = parseInt(msMonth.split('.')[0]);
    let setThisYear = parseInt(msMonth.split('.')[1]);
    d.setFullYear(setThisYear);
    d.setMonth(setThisMonth);
    d.getDate(0);
    d.setMilliseconds(0);
    const [msMonthStart, setMsMonthStart] = React.useState(d.getTime());

    const [childrenData, setChildrenData] = React.useState([]);
    const [currentChild, setCurrentChild] = React.useState({});
    const [workDaySelected, setWorkDaySelected] = React.useState(Date.now());

    const [workCreditDropdown, setWorkCreditDropdown] = React.useState(null);
    const [workCreditDollars, setWorkCreditDollars] = React.useState(0);

    const [workCredit, setWorkCredit] = React.useState(currentChild.work || []);
        // { id: '12.3.2022' /* day (1-31). month (0-11). year */, percent: 50 /* 0, 50, 100, 120 */, earnable: 5.00,  note: null },
        // { id: '13.3.2022' /* day (1-31). month (0-11). year */, percent: 100 /* 0, 50, 100, 120 */, earnable: 5.00,  note: null },
        // { id: '14.3.2022' /* day (1-31). month (0-11). year */, percent: 120 /* 0, 50, 100, 120 */, earnable: 5.00,  note: null },
        // { id: '18.3.2022' /* day (1-31). month (0-11). year */, percent: 100 /* 0, 50, 100, 120 */, earnable: 5.00, note: null },
        // { id: '19.3.2022' /* day (1-31). month (0-11). year */, percent: 100 /* 0, 50, 100, 120 */, earnable: 5.00,  note: null },
    // ]);

    React.useState(() => {
        getChildrenData()
            .then(res => {
                console.log(res);
                setChildrenData(res.data.children);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    React.useEffect(() => {
        document.getElementById("workMoneyInput").value = workCredit.sort((a, b) => { return parseInt(`${b.id.split('.')[2]}${b.id.split('.')[2]}${b.id.split('.')[0]}`) - parseInt(`${a.id.split('.')[2]}${a.id.split('.')[2]}${a.id.split('.')[0]}`) })[0]?.earnable || 5.00;
    }, []);

    React.useEffect(() => {
        console.log('child changed');
        if (currentChild.ledger) {
            setLedger(currentChild.ledger);
            setWorkCredit(currentChild.work);
        }
    }, [currentChild]);

    function msTimestampWithinCurrentWeek(date: number) {
        // get the millisecond start of the current week (sunday)
        let d = new Date(Date.now());
        d.setDate(d.getDate() - d.getDay() - 1);
        const msWeekStart = d.getTime();
        const msWeekEnd = msWeekStart + 604800000; // millisecond length of a week
        if (date >= msWeekStart && date < msWeekEnd) {
            return true;
        }
        return false;
    }

    if (user && !loading) {
        return (
            <div className="flex w-full min-h-screen">
                <div className="grow bg-dark place-self-stretch">
                </div>
                <div className="flex h-auto w-3/4 shrink-0">
                    {/* Sidebar */}
                    <div className="h-auto w-64 border-r bg-dark border-gray-200 shrink-0">
                        <div className="mt-7 mb-10 w-full h-10 flex items-center justify-center">
                            <p className="text-lg text-darkaccent">
                                Money Manager
                            </p>
                        </div>
                        <div>
                            {sidebar.map((item, index) => {
                                // if the current url path is the same as the redirect path, then make an active variable true
                                let active = window.location.pathname.endsWith(item.redirect) ? true : false;

                                return (
                                    <button key={index} className={`w-full h-12 flex items-center border-l-2 ${active ? 'border-darkhighlight text-darkhighlight bg-darkbg' : 'text-darkaccent border-dark'}`}>
                                        <item.icon className="w-5 h-5 mx-5"></item.icon>
                                        <p className="font-normal">{item.text}</p>
                                    </button>
                                )
                            })}
                        </div>
                    </div>
                    <div className="grow h-auto px-7 pb-28 bg-offwhite">
                        {/* Navbar */}
                        <div className="mt-7 mb-10 w-full h-10">
                            <div className="w-full h-full justify-between flex items-center">
                                <p className="text-xl text-gray-900 text-center">
                                    Overview
                                </p>
                                <Menu as="div" className="relative z-10">
                                    <Menu.Button className="flex self-end outline-none items-center group text-gray-900 gap-3">
                                        <div
                                            className={`overflow-hidden flex items-center justify-around`}>
                                            {user.username}
                                            {/* <BiChevronDown className={`text-2xl transform ${isOpen ? 'rotate-180' : 'rotate-0'} duration-200 ease-out`} /> */}
                                        </div>
                                        <div className="h-10 w-10 border-gray-300 group-focus:border-primary border-2 rounded-full flex justify-around items-center text-white font-semibold duration-100">
                                            <div className="select-none h-8 w-8 bg-primary rounded-full flex justify-around items-center text-white font-semibold">
                                                {user.username.slice(0, 1)}
                                            </div>
                                        </div>
                                    </Menu.Button>
                                    <Transition
                                        beforeEnter={() => setOpen(true)}
                                        enter="transition duration-200 ease-out"
                                        enterFrom="transform -translate-y-4 opacity-0"
                                        enterTo="transform translate-y-0 opacity-100"
                                        leave="transition duration-200 ease-in"
                                        leaveFrom="transform translate-y-0 opacity-100"
                                        leaveTo="transform -translate-y-4 opacity-0"
                                        beforeLeave={() => setOpen(false)}
                                    >
                                        <Menu.Items className={`absolute right-0 my-4 rounded outline-none`}>
                                            <div className="w-36 h-auto flex flex-col content-end align-right gap-1 border-primary bg-white border rounded-md p-1 shadow-lg">
                                                <DropdownItem text="Deposit" />
                                                <DropdownItem text="Withdraw" />
                                                <DropdownItem text="View Ledger" />
                                                <DropdownItem text="Account" />
                                                <DropdownItem text="Logout" type="danger" clickEvent={() => navigate('/logout')} />
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                        {/* Content */}
                            {parentDashboard()}
                    </div>
                </div>
                <div className="grow bg-offwhite place-self-stretch"></div>
            </div>
        );
    }

    return (
        <div>
            <ProtectedRoute />
        </div>
    )

    function parentDashboard() {
        return (
            <div className="flex items-center h-auto">
                <div className="w-full gap-7 flex flex-col">
                    {/* Balances */}
                    <Listbox value={currentChild} onChange={setCurrentChild}>
                        <div className="relative col-span-2">
                            <Listbox.Button className="relative flex items-center bg-gray-50 px-2 w-full h-10 border rounded-md border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow">
                                <p className="text-sm text-left">{currentChild.username || 'Select a child account'}</p>
                                <BiChevronDown className={`duration-200 ease-out text-xl ml-auto`} />
                            </Listbox.Button>
                            <Transition
                                beforeEnter={() => setListboxOpen(true)}
                                enter="transition duration-200 ease-out"
                                enterFrom="transform opacity-0"
                                enterTo="transform opacity-100"
                                leave="transition duration-200 ease-in"
                                leaveFrom="transform opacity-100"
                                leaveTo="transform opacity-0"
                                beforeLeave={() => setListboxOpen(false)}
                            >
                                <Listbox.Options className="absolute w-full rounded outline-none mt-3">
                                    <div className="w-full h-auto flex flex-col content-end align-right gap-1 border-primary bg-white border rounded-md p-1 shadow-lg">
                                        {childrenData.map((child: any, i: number) => {
                                            return (
                                                <SelectorItem currentData={setCurrentChild} text={child.username} data={child} />
                                            );
                                        })}
                                    </div>
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </Listbox>
                    <div className={!currentChild.userId ? `hidden` : `w-full gap-7 flex flex-col`}>
                        <div className="grid grid-cols-4 gap-7 w-full h-28">
                            <button className="w-full h-full bg-white border rounded-md border-gray-200 flex items-center justify-center hover:border-primary group focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow">
                                <div>
                                    <p className="text-base text-gray-400 text-center group-hover:text-primary">
                                        Savings
                                    </p>
                                    <p className={`mx-auto my-2 text-3xl ${savingsBalance >= 0 ? 'text-gray-900' : 'text-red-500'} group-hover:text-primary`}>
                                        {formatMoney(savingsBalance)}
                                    </p>
                                </div>
                            </button>
                            <button className="w-full h-full bg-white border rounded-md border-gray-200 flex items-center justify-center hover:border-primary group focus:ring-2 focus:border-primary ring-primary ring-opacity-50 transition-shadow">
                                <div>
                                    <p className="text-base text-gray-400 text-center group-hover:text-primary">
                                        Personal
                                    </p>
                                    <p className={`mx-auto my-2 text-3xl ${personalBalance >= 0 ? 'text-gray-900' : 'text-red-500'} group-hover:text-primary`}>
                                        {formatMoney(personalBalance)}
                                    </p>
                                </div>
                            </button>
                            <button className="w-full h-full bg-white border rounded-md border-gray-200 flex items-center justify-center hover:border-primary group focus:ring-2 focus:border-primary ring-primary ring-opacity-50 transition-shadow">
                                <div>
                                    <p className="text-base text-gray-400 text-center group-hover:text-primary">
                                        Donation
                                    </p>
                                    <p className={`mx-auto my-2 text-3xl ${donationBalance >= 0 ? 'text-gray-900' : 'text-red-500'} group-hover:text-primary`}>
                                        {formatMoney(donationBalance)}
                                    </p>
                                </div>
                            </button>
                            <div className="w-full h-full bg-white border rounded-md border-gray-200 flex items-center justify-center ">
                                <div>
                                    <p className="text-base text-gray-400 text-center group-hover:text-primary">
                                        Total
                                    </p>
                                    <p className={`mx-auto my-2 text-3xl ${savingsBalance + personalBalance + donationBalance >= 0 ? 'text-gray-900' : 'text-red-500'} group-hover:text-primary`}>
                                        {formatMoney(savingsBalance + personalBalance + donationBalance)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Recent transactions */}
                        <div className="w-full bg-white border rounded-md border-gray-200 flex flex-col py-7">
                            <div className="mb-2 h-14 w-full flex items-center justify-between shrink-0 px-7">
                                <div className="flex flex-col justify-between">
                                    <p className="text-lg text-gray-900">{currentChild.username}'s recent transactions</p>
                                    {/* <p className="text-gray-400 text-xs font-normal">as of 18 April 2022, 1:29 PM</p> */}
                                </div>
                                <div>
                                    <button onClick={() => setVisibleAddBar(true)} className="flex items-center text-sm text-primary hover:underline focus:underline">
                                        <FaPlus className="m-1"></FaPlus>
                                        Add
                                    </button>
                                </div>

                            </div>
                            <div className="w-full h-9 grid grid-cols-12 items-center gap-4 px-7">
                                <p className="text-sm col-span-4 text-gray-400">Description</p>
                                <p className="text-sm col-span-1 text-gray-400">Author</p>
                                <p className="text-sm col-span-2 text-gray-400 text-right">Total</p>
                                <p className="text-sm col-span-2 text-gray-400">Account</p>
                                <p className="text-sm col-span-3 text-gray-400">Date</p>
                            </div>
                            <div className={`w-full bg-gray-100 border-gray-200 border-t`}>
                                <form id='transactionForm' className={`${visibleAddBar ? '' : 'hidden'} px-7 w-full h-20 border-b bg-white border-gray-200 grid grid-cols-12 items-center gap-4`}>
                                    <input onChange={(e) => setNewTransactionDescription(e.target.value)} className="col-span-4 flex text-sm items-center bg-gray-50 px-4 w-full h-10 border rounded-md border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow" maxLength={90} placeholder="Enter a description" />
                                    <div className="col-span-1 flex items-center w-full h-10">
                                        <p className="text-sm text-gray-800 block w-full text-ellipsis">{user.username}</p>
                                    </div> 
                                    <div className="col-span-2 flex items-center w-full h-10">
                                        <div className="shrink-0 w-10 h-10 rounded-l-md border bg-gray-200 border-gray-200 text-sm flex items-center justify-center">
                                            $
                                        </div>
                                        <input onChange={(e) => handleMoneyInput(e, setNewTransactionTotal)} className="text-sm w-full px-2 h-full bg-gray-50 text-right rounded-r-md border border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow" placeholder="0.00" />
                                    </div>    
                                    <Listbox value={newTransactionAccount} onChange={setNewTransactionAccount}>
                                        <div className="relative col-span-2">
                                            <Listbox.Button className="relative flex items-center bg-gray-50 px-2 w-full h-10 border rounded-md border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow">
                                                <p className="text-sm text-left">{newTransactionAccount}</p>
                                                <BiChevronDown className={`${isListboxOpen ? 'rotate-180' : 'rotate-0'} duration-200 ease-out text-xl ml-auto`} />
                                            </Listbox.Button>
                                            <Transition
                                                beforeEnter={() => setListboxOpen(true)}
                                                enter="transition duration-200 ease-out"
                                                enterFrom="transform opacity-0"
                                                enterTo="transform opacity-100"
                                                leave="transition duration-200 ease-in"
                                                leaveFrom="transform opacity-100"
                                                leaveTo="transform opacity-0"
                                                beforeLeave={() => setListboxOpen(false)}
                                            >
                                                <Listbox.Options className="absolute w-full rounded outline-none mt-3">
                                                    <div className="w-full h-auto flex flex-col content-end align-right gap-1 border-primary bg-white border rounded-md p-1 shadow-lg">
                                                        <SelectorItem text="Savings" />
                                                        <SelectorItem text="Personal" />
                                                        <SelectorItem text="Donation" />
                                                    </div>
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                    <div className="text-sm col-span-2">
                                        <p className="text-gray-800 whitespace-nowrap">{unixMsStringToDate(Date.now())[0]}</p>
                                        <p className="text-gray-400 whitespace-nowrap font-normal">{unixMsStringToDate(Date.now())[1]}</p>
                                    </div>
                                    <div className="col-span-1 flex items-center justify-end gap-2">
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            console.log('submitted');
                                            const newTransaction = { total: newTransactionTotal, account: newTransactionAccount, date: Date.now(), description: newTransactionDescription };
                                            (async () => {
                                                addLedgerItem(currentChild.userId, newTransaction)
                                                    .then(({ data }: any) => {
                                                        if (!data.success) {
                                                            console.log('Uh oh, something went wrong');
                                                        } else {
                                                            console.log(data.ledger);
                                                            console.log('Successfully updated balance');
                                                            setLedger(data.ledger);
                                                            document.getElementById('transactionForm').reset();
                                                            setNewTransactionAccount('');
                                                            setNewTransactionTotal(0);
                                                            setNewTransactionDescription('');
                                                            setVisibleAddBar(false);
                                                            setCurrentChild({ ...currentChild, ledger: data.ledger });
                                                            const childrenDataCopy = childrenData.filter((child: any) => child.userId !== currentChild.userId);
                                                            childrenDataCopy.push({ ...currentChild, ledger: data.ledger });
                                                            setChildrenData(childrenDataCopy);
                                                        }
                                                    }).catch((err: Error) => {
                                                        console.log(err);
                                                    });
                                            })();
                                        }} className={`shrink-0 w-10 h-10 rounded-full ml-auto ${validTransaction ? 'bg-green-200 focus:bg-green-300 hover:bg-green-300 border-green-500' : 'bg-gray-100'} flex items-center justify-center focus:border`} disabled={!validTransaction} >
                                            <FaCheck className={`${validTransaction ? 'text-green-500' : 'text-gray-500'} text-lg`} disabled={!validTransaction} />
                                        </button>
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            setVisibleAddBar(false)
                                        }} className={`shrink-0 w-10 h-10 rounded-full ml-auto bg-red-100 focus:bg-red-200 hover:bg-red-200 border-red-500 flex items-center justify-center focus:border`} >
                                            <FaPlus className={`text-red-500 text-lg transform rotate-45`} disabled={!validTransaction} />
                                        </button>
                                    </div>
                                </form>
                                {ledger.length ? ledger.sort((a, b) => b.date - a.date).map((transaction, index) => (
                                    <div key={index} className={`px-7 w-full h-20 border-b bg-white hover:bg-gray-50 border-gray-200 grid grid-cols-12 items-center gap-4`}>
                                        <p className="block text-sm col-span-4 text-gray-800 line-clamp-2 w-full">{transaction.description}</p>
                                        <div className="col-span-1 flex items-center w-full h-10">
                                            <p className="text-sm text-gray-800 block w-full text-ellipsis">{transaction.author}</p>
                                        </div> 
                                        <p className={`text-right text-sm col-span-2 ${transaction.total >= 0 ? 'text-green-500' : 'text-red-600'}`}>{formatMoney(transaction.total)}</p>
                                        <p className="text-sm col-span-2 text-gray-800">{transaction.account}</p>
                                        <div className="text-sm col-span-2">
                                            <p className="text-gray-800 whitespace-nowrap">{unixMsStringToDate(transaction.date)[0]}</p>
                                            <p className="text-gray-400 whitespace-nowrap font-normal">{unixMsStringToDate(transaction.date)[1]}</p>
                                        </div>
                                        <div className="cols-span-1 flex items-center justify-end">
                                            <Menu as="div" className="relative">
                                                <Menu.Button className="w-10 h-10 rounded-full ml-auto hover:bg-gray-200 focus:bg-gray-100 flex items-center justify-center focus:border border-gray-200">
                                                    <HiDotsVertical className="text-gray-500 text-lg" />
                                                </Menu.Button>
                                                <Transition
                                                    beforeEnter={() => setOpen(true)}
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform -translate-y-1 opacity-0"
                                                    enterTo="transform translate-y-0 opacity-100"
                                                    leave="transition duration-100 ease-in"
                                                    leaveFrom="transform translate-y-0 opacity-100"
                                                    leaveTo="transform -translate-y-1 opacity-0"
                                                    beforeLeave={() => setOpen(false)}
                                                >
                                                    <Menu.Items className={`absolute right-0 rounded outline-none`}>
                                                        <div className="w-40 h-auto flex flex-col content-end align-right gap-1 border-red-600 bg-white border rounded-md p-1 shadow-lg">
                                                            <DropdownItem text="Permanently delete" type="danger" clickEvent={() => {
                                                                console.log(index);
                                                                console.log(transaction.id);
                                                                removeLedgerItem(currentChild.userId, transaction.id)
                                                                    .then(({ data }: any) => {
                                                                        if (!data.success) {
                                                                            console.log('Uh oh, something went wrong');
                                                                        } else {
                                                                            console.log(data.ledger);
                                                                            console.log('Successfully updated balance');
                                                                            setLedger(data.ledger);
                                                                            setCurrentChild({ ...currentChild, ledger: data.ledger });
                                                                            const childrenDataCopy = childrenData.filter((child: any) => child.userId !== currentChild.userId);
                                                                            childrenDataCopy.push({ ...currentChild, ledger: data.ledger });
                                                                            setChildrenData(childrenDataCopy);
                                                                        }
                                                                    }).catch((err: Error) => {
                                                                        console.log(err);
                                                                    });
                                                            }
                                                            } />
                                                        </div>
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                )) :
                                    <div className='px-7 w-full h-20 bg-gray-100 border-gray-200 items-center gap-4 justify-center flex border-b'>
                                        <p className="text-sm text-gray-400">{currentChild.username} hasn't made any transactions recently...</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-2 gap-7">
                            <div className="bg-white rounded-md border border-gray-200 flex flex-col py-7">
                                <div className="mb-2 h-14 w-full flex items-center justify-between shrink-0 px-7">
                                    <div className="flex flex-col justify-between">
                                        <p className="text-lg text-gray-900">Effort credit</p>
                                    </div>
                                </div>
                                <div className="w-full h-9 grid grid-cols-12 items-center gap-4 px-7">
                                    <p className="text-sm col-span-4 text-gray-400">Percent effort</p>
                                    <p className="text-sm col-span-4 text-gray-400">Date</p>
                                    <p className={`text-sm col-span-3 text-gray-400`}>{!msTimestampWithinCurrentWeek(workDaySelected) ? 'Earned' : 'Earnable'}</p>
                                </div>
                                <form id='workForm' className={`${msTimestampWithinCurrentWeek(workDaySelected) ? '' : 'hidden'} px-7 w-full h-20 border-t bg-white border-gray-200 grid grid-cols-12 items-center gap-4`}>
                                    <input onChange={(e) => setNewTransactionDescription(e.target.value)} className="hidden col-span-4 flex text-sm items-center bg-gray-50 px-4 w-full h-10 border rounded-md border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow" maxLength={90} placeholder="Enter a description" />
                                    <Listbox value={workCreditDropdown} onChange={setWorkCreditDropdown}>
                                        <div className="relative col-span-4">
                                            <Listbox.Button className="relative flex items-center bg-gray-50 px-2 w-full h-10 border rounded-md border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow">
                                                {console.log(workCreditDropdown)}
                                                <p className="text-sm text-left">{workCreditDropdown?.message || ''}</p>
                                                <BiChevronDown className={`duration-200 ease-out text-xl ml-auto`} />
                                            </Listbox.Button>
                                            <Transition
                                                beforeEnter={() => setListboxOpen(true)}
                                                enter="transition duration-200 ease-out"
                                                enterFrom="transform opacity-0"
                                                enterTo="transform opacity-100"
                                                leave="transition duration-200 ease-in"
                                                leaveFrom="transform opacity-100"
                                                leaveTo="transform opacity-0"
                                                beforeLeave={() => setListboxOpen(false)}
                                            >
                                                <Listbox.Options className="absolute w-full rounded outline-none mt-3">
                                                    <div className="w-full h-auto flex flex-col content-end align-right gap-1 border-primary bg-white border rounded-md p-1 shadow-lg">
                                                        <SelectorItem text="No credit (0%)" data={{ message: "No credit (0%)", percent: 0}} />
                                                        <SelectorItem text="Half day (50%)" data={{ message: "Half day (50%)", percent: 50 }} />
                                                        <SelectorItem text="Full day (100%)" data={{ message: "Full day (100%)", percent: 100 }} />
                                                        <SelectorItem text="Overtime (120%)" data={{ message: "Overtime (120%)", percent: 120 }} />
                                                    </div>
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </Listbox>
                                    <div className="text-sm col-span-4">
                                        <p className="text-gray-800 whitespace-nowrap">{weekdays[new Date(workDaySelected).getDay()]}</p>
                                        <p className="text-gray-400 whitespace-nowrap font-normal">{unixMsStringToDate(workDaySelected)[0]}</p>
                                    </div>
                                    <div className="col-span-3 flex items-center w-11/12 h-10">
                                        <div className="shrink-0 w-10 h-10 rounded-l-md border bg-gray-200 border-gray-200 text-sm flex items-center justify-center">
                                            $
                                        </div>
                                        <input id="workMoneyInput" onChange={(e) => handleMoneyInput(e, setWorkCreditDollars, false)} className="text-sm w-full px-2 h-full bg-gray-50 text-right rounded-r-md border border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow" placeholder="0.00" />
                                    </div>   
                                    <div className="col-span-1 flex items-center justify-end gap-2">
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            console.log('submitted');
                                            const thisDate = new Date(workDaySelected);
                                            console.log(`${thisDate.getDate()}.${thisDate.getMonth()}.${thisDate.getFullYear()}`);
                                            const newWork = { percent: workCreditDropdown.percent, earnable: 5.00, id: `${thisDate.getDate()}.${thisDate.getMonth()}.${thisDate.getFullYear()}`, note: null };
                                            console.log(newWork);
                                            (async () => {
                                                addWorkItem(currentChild.userId, newWork)
                                                    .then(({ data }: any) => {
                                                        if (!data.success) {
                                                            console.log('Uh oh, something went wrong');
                                                        } else {
                                                            console.log(data.work);
                                                            console.log('Successfully updated balance');
                                                            setWorkCredit(data.work);
                                                            setCurrentChild({ ...currentChild, work: data.work });
                                                            const childrenDataCopy = childrenData.filter((child: any) => child.userId !== currentChild.userId);
                                                            childrenDataCopy.push({ ...currentChild, work: data.work });
                                                            setChildrenData(childrenDataCopy);
                                                        }
                                                    }).catch((err: Error) => {
                                                        console.log(err);
                                                    });
                                            })();
                                        }} className={`shrink-0 w-10 h-10 rounded-full ml-auto ${(workCreditDropdown && workCreditDollars !== 0) ? 'bg-green-200 focus:bg-green-300 hover:bg-green-300 border-green-500' : 'bg-gray-100'} flex items-center justify-center focus:border`} disabled={!(workCreditDropdown && workCreditDollars !== 0)} >
                                            <FaCheck className={`${(workCreditDropdown && workCreditDollars !== 0) ? 'text-green-500' : 'text-gray-500'} text-lg`} />
                                        </button>
                                    </div>
                                </form>
                                <div className={`${msTimestampWithinCurrentWeek(workDaySelected) ? 'hidden' : ''} px-7 w-full h-20 border-t bg-white border-gray-200 grid grid-cols-12 items-center gap-4`}>
                                    <input onChange={(e) => setNewTransactionDescription(e.target.value)} className="hidden col-span-4 flex text-sm items-center bg-gray-50 px-4 w-full h-10 border rounded-md border-gray-200 hover:border-primary focus:border-primary focus:ring-2 ring-primary ring-opacity-50 transition-shadow" maxLength={90} placeholder="Enter a description" />
                                    <div className="relative col-span-4">
                                            <div className="relative flex items-center w-full h-10">
                                                {console.log(workCreditDropdown)}
                                                <p className="text-sm text-left">{workCreditDropdown?.message || ''}</p>
                                            </div>
                                        </div>
                                    <div className="text-sm col-span-4">
                                        <p className="text-gray-800 whitespace-nowrap">{weekdays[new Date(workDaySelected).getDay()]}</p>
                                        <p className="text-gray-400 whitespace-nowrap font-normal">{unixMsStringToDate(workDaySelected)[0]}</p>
                                    </div>
                                    <div className="col-span-3 flex items-center w-11/12 h-10">
                                        <div>{formatMoney(workCreditDollars * (workCreditDropdown?.percent/100))}</div> 
                                    </div>
                                </div>
                                <div className="w-full h-9 justify-between flex items-center gap-4 border-t border-gray-200">
                                    <button onClick={(e) => {
                                        let month = parseInt(msMonth.split('.')[0]);
                                        let year = parseInt(msMonth.split('.')[1]);
                                        month -= 1;
                                        if (month < 0) {
                                            month = 11;
                                            year -= 1;
                                        }
                                        let d = new Date();
                                        d.setFullYear(year);
                                        d.setMonth(month);
                                        d.getDate(0);
                                        d.setMilliseconds(0);
                                        setMsMonthStart(d.getTime());
                                        setMsMonth(`${month}.${year}`);
                                    }} className="text-lg h-9 w-12 shrink-0 text-gray-400 hover:text-primary focus:text-primary active:bg-gray-100 flex items-center justify-center border-r border-gray-200">
                                        <FaCaretLeft></FaCaretLeft>
                                    </button>
                                    <p className="h-full flex items-center grow text-gray-900 justify-center">{`${months[parseInt(msMonth.split('.')[0])]} ${msMonth.split('.')[1]}`}</p>
                                    <button onClick={(e) => {
                                        let month = parseInt(msMonth.split('.')[0]);
                                        let year = parseInt(msMonth.split('.')[1]);
                                        month += 1;
                                        if (month > 11) {
                                            month = 0;
                                            year += 1;
                                        }
                                        let d = new Date();
                                        d.setFullYear(year);
                                        d.setMonth(month);
                                        d.getDate(0);
                                        d.setMilliseconds(0);
                                        setMsMonthStart(d.getTime());
                                        setMsMonth(`${month}.${year}`);
                                    }} className="text-lg h-9 w-12 shrink-0 text-gray-400 hover:text-primary focus:text-primary active:bg-gray-100 flex items-center justify-center border-l border-gray-200">
                                        <FaCaretRight></FaCaretRight>
                                    </button>
                                </div>
                                <div className="w-full bg-gray-50 border-gray-200 border-y px-14">
                                    <div className="w-full grid grid-cols-7 text-gray-400">
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">Su</p>
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">Mo</p>
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">Tu</p>
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">We</p>
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">Th</p>
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">Fr</p>
                                        <p className="col-span-1 h-10 flex items-center w-full justify-center text-sm px-1">Sa</p>
                                    </div>
                                    <div className="w-full grid grid-cols-7">
                                        {Array(42).fill().map((item, i) => {
                                            const selfDate = getCalendarDate(msMonthStart, i);
                                            const today = new Date(Date.now());
                                            const isToday = today.getFullYear() === selfDate.year && today.getMonth() === selfDate.month && today.getDate() === selfDate.date;
                                            let selfDateInMS =  new Date(Date.now()).setFullYear(selfDate.year, selfDate.month, selfDate.date);

                                            const work = workCredit.find((workI: any) => {
                                                const [date, month, year] = workI.id.split('.');
                                                return parseInt(date) === selfDate.date && parseInt(month) === selfDate.month && parseInt(year) === selfDate.year;
                                            });
                                            if (!work) {
                                                return (
                                                    <button onClick={() => {
                                                        setWorkDaySelected(selfDateInMS);
                                                        const selfId = `${selfDate.date}.${selfDate.month}.${selfDate.year}`;
                                                        const selfData = workCredit.find((workI: any) => workI.id === selfId) || { id: selfId, earnable: workCredit.sort((a, b) => { return parseInt(`${b.id.split('.')[2]}${b.id.split('.')[2]}${b.id.split('.')[0]}`) - parseInt(`${a.id.split('.')[2]}${a.id.split('.')[2]}${a.id.split('.')[0]}`) })[0]?.earnable || 5.00, note:  '' };
                                                        console.log(selfData, selfId);
                                                        setWorkCreditDollars(selfData ? selfData.earnable : 0);
                                                        document.getElementById("workMoneyInput").value = selfData ? selfData.earnable : 0;
                                                        const work = new Map();
                                                        work.set(0, { message: "No credit (0%)", percent: 0 });
                                                        work.set(50, { message: "Half day (50%)", percent: 50 });
                                                        work.set(100, { message: "Full day (100%)", percent: 100 });
                                                        work.set(120, { message: "Overtime (120%)", percent: 120 });
                                                        setWorkCreditDropdown(selfData.percent ? work.get(selfData.percent) : msTimestampWithinCurrentWeek(selfDateInMS) ? '' : { message: 'Not applicable', percent: null });
                                                        }} key={i} className="p-1 group" disabled={!selfDate.insideMonth || Date.now() < selfDateInMS}>
                                                        <div className={`mx-auto ${selfDateInMS === workDaySelected ? 'border' : ''} border-primary col-span-1 h-10 w-10 ${selfDate.insideMonth ? isToday ? 'border border-primary text-primary' : 'text-gray-600' : 'text-gray-300'} group-hover:bg-gray-200 rounded-full flex items-center justify-center text-sm`}>{selfDate.date}</div>
                                                    </button>
                                                );
                                            }
                                            return (
                                                <button key={i} onClick={() => {
                                                    setWorkDaySelected(selfDateInMS);
                                                    const selfId = `${selfDate.date}.${selfDate.month}.${selfDate.year}`;
                                                    const selfData = workCredit.find((workI: any) => workI.id === selfId) || { id: selfId, earnable: workCredit.sort((a, b) => { return parseInt(`${b.id.split('.')[2]}${b.id.split('.')[2]}${b.id.split('.')[0]}`) - parseInt(`${a.id.split('.')[2]}${a.id.split('.')[2]}${a.id.split('.')[0]}`) })[0]?.earnable || 5.00, note: '' };
                                                    console.log(selfData, selfId);
                                                    setWorkCreditDollars(selfData ? selfData.earnable : 0);
                                                    document.getElementById("workMoneyInput").value = selfData ? selfData.earnable : 0;
                                                    const work = new Map();
                                                    work.set(0, { message: "No credit (0%)", percent: 0 });
                                                    work.set(50, { message: "Half day (50%)", percent: 50 });
                                                    work.set(100, { message: "Full day (100%)", percent: 100 });
                                                    work.set(120, { message: "Overtime (120%)", percent: 120 });
                                                    setWorkCreditDropdown(selfData.percent !== undefined ? work.get(selfData.percent) : msTimestampWithinCurrentWeek(selfDateInMS) ? '' : { message: 'Not applicable', percent: null });
                                                }} className="p-1 group" disabled={!selfDate.insideMonth || Date.now() < selfDateInMS}>
                                                    <div className={`mx-auto ${selfDateInMS === workDaySelected ? 'border' : ''} border-primary col-span-1 h-10 w-10 ${work.percent < 25 ? 'bg-red-300 group-hover:bg-red-400' : work.percent < 100 ? 'bg-yellow-300 group-hover:bg-yellow-400' : work.percent < 120 ? 'bg-green-200 group-hover:bg-green-300' : work.percent < 200 ? 'bg-blue-300 group-hover:bg-blue-400' : isToday ? 'group-hover:bg-gray-200' : 'group-hover:bg-gray-200'} ${selfDate.insideMonth ? isToday ? 'border border-primary text-primary' : 'text-gray-600' : 'text-gray-300'} rounded-full flex items-center justify-center text-sm`}>{selfDate.date}</div>
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}